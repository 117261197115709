.chat {
  position: fixed;
  bottom: 75px;
  right: 75px;

  z-index: 300;

  &__btn {
    position: absolute;
    left: 0;
    top: 0;

    width: 52px;
    height: 52px;
    background-color: rgba(18, 18, 18, 1);
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    border-radius: 50%;

    z-index: 350;

    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .icon-in {
      background-color: rgba(18, 18, 18, 1);
      opacity: 1;
      z-index: 360;

      transition: all 0.3s ease-in-out;
    }

    .icon-out {
      background-color: rgba(18, 18, 18, 1);
      opacity: 0;

      transition: all 0.3s ease-in-out;
    }
  }

  &__mess,
  &__zalo {
    position: absolute;
    top: 1px;
    left: 1px;

    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: unset;
    border-radius: 50%;
    padding: 8px;

    transition: all 0.3s ease-in-out;
  }
}

.chat-show {
  .chat__mess {
    padding: 10px;
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    transform: translateY(calc(-100% - 12px));
  }

  .chat__zalo {
    padding: 10px;
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    transform: translateY(calc(-200% - 12px - 8px));
  }

  .chat__btn {
    .icon-in {
      opacity: 0;
    }

    .icon-out {
      opacity: 1;
    }
  }
}

@media all and (max-width: 560px) {
  .chat {
    position: fixed;
    bottom: 65px;
    right: 65px;
  }
}
