/* hover-underline-animation */
.hover-underline-animation {
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: var(--Color-Text-high-emphasis, #121212);
    transform-origin: bottom left;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}
