@import "./../../../src/variable.scss";

.checkout--page {
  margin-top: 128px;

  @media (max-width: 577px) {
    .product--card .product--card-image {
      height: 180px;
    }
  }

  .swiper {
    padding-bottom: 80px;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    width: 65%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8px;

    background-color: transparent;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-scrollbar {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 90%;
    left: 0;

    background-color: $low-emphasis;

    .swiper-scrollbar-drag {
      top: -200%;

      height: 6px;
      background-color: $high-emphasis;
      border-radius: 1px;
    }
  }

  .checkout--page--container {
    display: flex;
    padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-xl, 32px);
    align-self: stretch;
    background: #f7f7f7;

    .checkout--page--container--title {
      color: var(--Color-Text-high-emphasis, #121212);

      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;
    }

    .checkout--page--container--main {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;

      .checkout--page--container--main--form {
        width: 60%;

        display: flex;
        align-items: flex-start;
        align-self: stretch;

        .form--info {
          width: 100%;

          display: flex;
          padding: var(--Spacing-space-empty, 0px);
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 40px;

          .form--info--container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            .form--info--container--address {
              display: flex;
              padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px) var(--Spacing-space-lg, 24px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-lg, 24px);
              align-self: stretch;
              background: #fff;

              p {
                color: var(--Color-Text-high-emphasis, #121212);

                font-size: 24px;
                font-style: normal;
                font-weight: 800;
                line-height: 36px;
                letter-spacing: 0.12px;
              }

              .form--info--container--address--sub {
                display: flex;
                height: 20px;
                align-items: flex-start;
                gap: var(--Spacing-space-s, 8px);

                p {
                  color: var(--Color-Text-medium-emphsis, #525257);

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.28px;
                }
              }

              .form--info--container--address--row {
                display: flex;
                align-items: flex-start;
                gap: 24px;
                align-self: stretch;
              }
            }
          }

          .form--info--policy {
            display: flex;
            align-items: center;
            gap: var(--Spacing-space-xs, 6px);
            align-self: stretch;

            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.28px;
          }

          .form--info--button {
            display: flex;
            width: 360px;
          }
        }
      }

      .checkout--page--container--main--product {
        width: calc(40% - 40px);

        display: flex;
        flex-direction: column;
        gap: 32px;

        padding: 32px;
        background-color: #fff;

        .checkout--page--container--main--product--title {
          color: $high-emphasis;
          font-size: 24px;
          font-weight: 800;
        }

        .checkout-table {
          td {
            vertical-align: middle;
          }

          &__header {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 600;

            &__product {
              width: 55%;
            }

            &__qty {
              width: 20%;
            }

            &__price {
              width: 25%;
            }
          }

          &__row {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 500;

            &__product {
              width: 100%;

              display: flex;
              flex-direction: row;
              justify-content: start;
              align-items: center;
              gap: 8px;

              img {
                width: 100px;
              }

              &__name {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;

                &__text {
                  font-size: 14px;
                  font-weight: 500;
                  color: $high-emphasis;
                }

                &__number {
                  display: none;
                }
              }
            }

            &__qty {
              width: 20%;
            }

            &__price {
              width: 25%;

              &__org {
                color: $medium-emphasis;
                font-size: 10px;
                font-weight: 500;
                text-decoration: line-through;
              }

              &__sale {
                color: #ab253f;
                font-size: 12px;
                font-weight: 600;
              }
            }
          }
        }

        .line {
          width: 100%;
          height: 1px;
          background-color: rgba(29, 29, 31, 0.16);
        }

        .delivery-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 2px 0;

          &__left {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
          }

          &__right {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
          }
        }

        .total-temp {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &__text {
            color: $high-emphasis;
            font-size: 18px;
            font-weight: 700;
          }

          &__number {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media all and (max-width: 960px) {
  .checkout--page {
    margin-top: 56px;

    .checkout--page--container {
      display: flex;
      padding: 40px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Spacing-space-xl, 32px);
      align-self: stretch;
      background: #f7f7f7;

      .checkout--page--container--title {
        color: var(--Color-Text-high-emphasis, #121212);
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px;
      }

      .checkout--page--container--main {
        width: 100%;

        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        align-items: start;
        gap: 32px;

        .checkout--page--container--main--form {
          width: 100%;

          display: flex;
          align-items: flex-start;
          align-self: stretch;

          .form--info {
            width: 100%;

            display: flex;
            padding: var(--Spacing-space-empty, 0px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 40px;

            .form--info--container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-m, 16px);
              align-self: stretch;

              .form--info--container--address {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-lg, 24px);
                align-self: stretch;
                background: #fff;

                p {
                  color: var(--Color-Text-high-emphasis, #121212);

                  font-size: 24px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 36px;
                  letter-spacing: 0.12px;
                }

                .form--info--container--address--sub {
                  display: flex;
                  height: 20px;
                  align-items: flex-start;
                  gap: var(--Spacing-space-s, 8px);

                  p {
                    color: var(--Color-Text-medium-emphsis, #525257);

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.28px;
                  }
                }

                .form--info--container--address--row {
                  display: flex;
                  align-items: flex-start;
                  gap: 24px;
                  align-self: stretch;
                  flex-direction: column;
                }
              }
            }

            .form--info--policy {
              display: flex;
              align-items: center;
              gap: var(--Spacing-space-xs, 6px);
              align-self: stretch;

              color: var(--Color-Text-medium-emphsis, #525257);

              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.28px;
            }

            .form--info--button {
              display: flex;
              width: 100%;
            }
          }
        }

        .checkout--page--container--main--product {
          width: 100%;

          display: flex;
          flex-direction: column;
          gap: 32px;

          padding: 16px;
          background-color: #fff;

          .checkout--page--container--main--product--title {
            color: $high-emphasis;
            font-size: 24px;
            font-weight: 800;
          }

          .checkout-table {
            td {
              vertical-align: middle;
            }

            &__header {
              display: none;

              // color: $high-emphasis;
              // font-size: 14px;
              // font-weight: 600;

              // &__product {
              //   width: 55%;
              // }

              // &__qty {
              //   width: 20%;
              // }

              // &__price {
              //   width: 25%;
              // }
            }

            &__row {
              color: $high-emphasis;
              font-size: 14px;
              font-weight: 500;

              &__product {
                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                gap: 16px;

                img {
                  width: 120px;
                }

                &__name {
                  display: flex;
                  flex-direction: column;
                  justify-content: start;
                  align-items: start;

                  &__text {
                    font-size: 14px;
                    font-weight: 500;
                    color: $high-emphasis;
                  }

                  &__number {
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    color: $medium-emphasis;
                  }
                }
              }

              &__qty {
                width: 0%;
                display: none;
              }

              &__price {
                width: 25%;

                &__org {
                  color: $medium-emphasis;
                  font-size: 10px;
                  font-weight: 500;
                  text-decoration: line-through;
                }

                &__sale {
                  color: #ab253f;
                  font-size: 12px;
                  font-weight: 600;
                }
              }
            }
          }

          .line {
            width: 100%;
            height: 1px;
            background-color: rgba(29, 29, 31, 0.16);
          }

          .delivery-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 2px 0;

            &__left {
              color: $medium-emphasis;
              font-size: 14px;
              font-weight: 500;
            }

            &__right {
              color: $high-emphasis;
              font-size: 14px;
              font-weight: 500;
              text-align: right;
            }
          }

          .total-temp {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__text {
              color: $high-emphasis;
              font-size: 18px;
              font-weight: 700;
            }

            &__number {
              color: $high-emphasis;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}