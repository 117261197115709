@import "./../../../src/variable.scss";

.cart--page {
  margin-top: 128px;
  @media (max-width: 577px) {
    .product--card .product--card-image {
      height: 180px;
    }
  }

  .swiper {
    padding-bottom: 80px;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    width: 65%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8px;

    background-color: transparent;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-scrollbar {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 90%;
    left: 0;

    background-color: $low-emphasis;

    .swiper-scrollbar-drag {
      top: -200%;

      height: 6px;
      background-color: $high-emphasis;
      border-radius: 1px;
    }
  }
  &--category {
    display: flex;
    padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-xl, 32px);
    align-self: stretch;
    background: #f7f7f7;

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--Spacing-space-xxl, 40px);
      align-self: stretch;

      &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--Spacing-space-xl, 32px);
        align-self: stretch;

        &--title {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-space-xl, 32px);
          align-self: stretch;

          span {
            align-self: stretch;
            color: var(--Color-Text-high-emphasis, #121212);
            text-align: center;

            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 36px;
            letter-spacing: 0.12px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 960px) {
  .cart--page {
    margin-top: 56px;

    &--category {
      display: flex;
      padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Spacing-space-xl, 32px);
      align-self: stretch;
      background: #f7f7f7;

      &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;

        &--content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-space-xl, 32px);
          align-self: stretch;

          &--title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            span {
              align-self: stretch;
              color: var(--Color-Text-high-emphasis, #121212);
              text-align: center;

              font-size: 24px;
              font-style: normal;
              font-weight: 800;
              line-height: 36px;
              letter-spacing: 0.12px;
            }
          }
        }
      }
    }
  }
}
