@media (min-width:320px) {
    .product--category {
        margin-top: 80px;

        background: var(--background, #FFF);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        .product--category--header {
            display: none;
        }

        .product--category--container {
            display: flex;
            padding: var(--Spacing-space-xxl, 40px) var(--Spacing-space-m, 16px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            background: #F7F7F7;

            .product--category--container--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .product--category--container--content-title {
                    align-self: stretch;
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 60px;
                }

                .product--category--container--content-subtitle {
                    align-self: stretch;
                    color: var(--Color-Text-medium-emphsis, #525257);

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.28px;
                }
            }

            .product--category--container--filter {
                display: flex;
                padding: var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;
                overflow-x: auto;

                .product--category--container--filter--selection {
                    display: flex;
                    min-width: 180px;
                    height: 180px;
                    padding: var(--Spacing-space-empty, 0px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-empty, 0px);

                    border-radius: var(--Radius-radius-xsss, 2px);
                    background: #FFF;
                    cursor: pointer;
                    transition: all .3s ease-in-out;
                    box-sizing: border-box;

                    &:hover .product--category--container--filter--selection-description {
                        background: var(--Color-Base-base-black, #1D1D1F);

                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-wihte, #FFF);

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.14px;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);

                            span {
                                align-self: stretch;
                                color: var(--Color-Text-high-emphasis, #121212);

                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0.14px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .product--category--container--filter--selection-image {
                        flex: 1 0 0;
                        align-self: stretch;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }


                    }



                    .product--category--container--filter--selection-description {
                        display: flex;
                        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-lg, 24px);
                        flex-direction: column;
                        align-items: flex-start;
                        gap: var(--Spacing-space-xss, 4px);
                        align-self: stretch;
                        background: #EDEDED;


                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-emphasis, #121212);

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                            text-transform: uppercase;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);
                        }
                    }
                }
            }
        }

        .product--list {
            display: flex;
            padding: var(--Spacing-space-xxl, 40px) var(--Spacing-space-m, 16px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            .product--list--title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .product--list--title--filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);


                    .product--list--title--filter-title {
                        display: none;
                    }

                    .product--list--title--filter-item {
                        display: none;
                    }

                    .mobile--product--list--title--filter-item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }
                }

                .product--list--right {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;

                    .product--list--right-pagination {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        span {
                            color: var(--Color-Text-medium-emphsis, #525257);

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0.28px;
                        }
                    }

                    .product--list--right-sort {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .btn--sort {
                            display: flex;
                            padding: 8px var(--Spacing-space-empty, 0px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-space-xss, 4px);

                            span {
                                color: var(--Color-Text-high-emphasis, #121212);
                                text-align: center;

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }

            .product--list--container {
                display: flex;
                flex-direction: row;
                row-gap: var(--Spacing-space-lg, 24px);
                align-items: flex-start;
                column-gap: 8px;
                align-self: stretch;
                flex-wrap: wrap;

                .product--list--container-item {
                    width: calc((100% - 8px) / 2);
                }
            }


        }

        .product--pagination {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) 0px var(--Spacing-web-margin-L, 60px) 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }


    }

    .price--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .price--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            align-self: stretch;
        }

        .price--filter--container-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            .container--label {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px var(--Spacing-space-s, 8px);
                align-self: stretch;
                flex-wrap: wrap;
            }


        }
    }

    .material--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .material--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .material--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            padding-top: var(--Spacing-space-m, 16px);
            align-self: stretch;
        }
    }

    .color--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .color--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .color--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            padding-top: var(--Spacing-space-m, 16px);
            align-self: stretch;
        }
    }


}

@media (min-width:576px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    .product--category {
        margin-top: 128px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        background: var(--background, #FFF);
        position: relative;


        .product--category--header {
            display: flex;
            align-self: stretch;
            width: 100%;
            max-width: 1440px;
            position: fixed;
            top: 80px;
            opacity: 0;
            z-index: -1;
            /* Dropshadow Widget 16 */
            box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.10);
            background: rgba(255, 255, 255, 0.72);
            backdrop-filter: blur(12px);
            transition: all .1s ease-in-out;

            &.show {
                opacity: 1;
                z-index: 100;
            }


            .product--category--header-sub {
                display: flex;
                flex-direction: row;
                padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
                align-items: center;
                gap: var(--Spacing-space-lg, 24px);
                align-self: stretch;
                justify-content: space-between;
                align-items: center;
                flex: 1 0 0;

                .header--filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .header--filter-title {
                        display: inline;
                        color: var(--Color-Text-high-emphasis, #121212);

                        /* Title/Small */

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        /* 150% */
                        letter-spacing: 0.16px;
                    }

                    .header--filter-item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .mobile--header--filter-item {
                        display: none;

                    }
                }

                .header--right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .header--right-pagination {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .header--right-sort {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .btn--sort {
                            display: flex;
                            padding: var(--Spacing-space-empty, 0px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-space-xss, 4px);

                            span {
                                color: var(--Color-Text-high-emphasis, #121212);
                                text-align: center;

                                /* Label/Small */

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                /* 133.333% */
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }
        }

        .product--category--container {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            background: #F7F7F7;

            .product--category--container--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .product--category--container--content-title {
                    align-self: stretch;
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Headline/Display */

                    font-size: 72px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 96px;
                    /* 133.333% */
                }

                .product--category--container--content-subtitle {
                    align-self: stretch;
                    color: var(--Color-Text-medium-emphsis, #525257);

                    /* Body Text/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 0.32px;
                }
            }

            .product--category--container--filter {
                display: flex;
                padding: var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;

                .product--category--container--filter--selection {
                    display: flex;
                    width: 180px;
                    height: 180px;
                    padding: var(--Spacing-space-empty, 0px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-empty, 0px);

                    border-radius: var(--Radius-radius-xsss, 2px);
                    background: #FFF;
                    cursor: pointer;
                    transition: all .3s ease-in-out;

                    &:hover .product--category--container--filter--selection-description {
                        background: var(--Color-Base-base-black, #1D1D1F);

                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-wihte, #FFF);

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);

                            span {
                                align-self: stretch;
                                color: var(--Color-Text-high-emphasis, #121212);

                                /* Label/Medium */

                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                /* 142.857% */
                                letter-spacing: 0.14px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .product--category--container--filter--selection-image {
                        flex: 1 0 0;
                        align-self: stretch;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }


                    }



                    .product--category--container--filter--selection-description {
                        display: flex;
                        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-lg, 24px);
                        flex-direction: column;
                        align-items: flex-start;
                        gap: var(--Spacing-space-xss, 4px);
                        align-self: stretch;
                        background: #EDEDED;


                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-emphasis, #121212);

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                            text-transform: uppercase;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);
                        }
                    }
                }
            }
        }

        .product--list {
            display: flex;
            padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            .product--list--title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                &.hidden {
                    opacity: 0;
                }

                .product--list--title--filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);
                    opacity: 1;
                    transition: all .1s ease-in-out;


                    .product--list--title--filter-title {
                        display: inline;
                        color: var(--Color-Text-high-emphasis, #121212);

                        /* Title/Small */

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        /* 150% */
                        letter-spacing: 0.16px;
                    }

                    .product--list--title--filter-item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .mobile--product--list--title--filter-item {
                        display: none;
                    }
                }

                .product--list--right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .product--list--right-pagination {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        span {
                            color: var(--Color-Text-medium-emphsis, #525257);

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0.28px;
                        }
                    }

                    .product--list--right-sort {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .btn--sort {
                            display: flex;
                            padding: var(--Spacing-space-empty, 0px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-space-xss, 4px);

                            span {
                                color: var(--Color-Text-high-emphasis, #121212);
                                text-align: center;

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }

            .product--list--container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                column-gap: 16px;
                row-gap: 30px;
                align-self: stretch;
                flex-wrap: wrap;

                .product--list--container-item {
                    width: calc((100% - 32px) / 3);
                }
            }


        }

        .product--pagination {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) 0px var(--Spacing-web-margin-L, 60px) 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }


    }

    .price--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .price--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            align-self: stretch;
            padding-top: var(--Spacing-space-m, 16px);
        }

        .price--filter--container-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            .container--label {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px var(--Spacing-space-s, 8px);
                align-self: stretch;
                flex-wrap: wrap;
            }


        }
    }

    .material--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .material--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .material--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            padding-top: var(--Spacing-space-m, 16px);
            align-self: stretch;
        }
    }

    .color--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .color--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .color--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            padding-top: var(--Spacing-space-m, 16px);
            align-self: stretch;
        }
    }
}

@media (min-width:1441px) {

    .product--category {
        margin-top: 128px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        background: var(--background, #FFF);
        position: relative;


        .product--category--header {
            display: flex;
            align-self: stretch;
            width: 100%;
            max-width: 1920px;
            position: fixed;
            top: 80px;
            opacity: 0;
            z-index: -1;
            /* Dropshadow Widget 16 */
            box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.10);
            background: rgba(255, 255, 255, 0.72);
            backdrop-filter: blur(12px);
            transition: all .1s ease-in-out;

            &.show {
                opacity: 1;
                z-index: 100;
            }


            .product--category--header-sub {
                display: flex;
                flex-direction: row;
                padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
                align-items: center;
                gap: var(--Spacing-space-lg, 24px);
                align-self: stretch;
                justify-content: space-between;
                align-items: center;
                flex: 1 0 0;

                .header--filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .header--filter-title {
                        display: inline;
                        color: var(--Color-Text-high-emphasis, #121212);

                        /* Title/Small */

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        /* 150% */
                        letter-spacing: 0.16px;
                    }

                    .header--filter-item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .mobile--header--filter-item {
                        display: none;

                    }
                }

                .header--right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .header--right-pagination {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .header--right-sort {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .btn--sort {
                            display: flex;
                            padding: var(--Spacing-space-empty, 0px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-space-xss, 4px);

                            span {
                                color: var(--Color-Text-high-emphasis, #121212);
                                text-align: center;

                                /* Label/Small */

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                /* 133.333% */
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }
        }

        .product--category--container {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            background: #F7F7F7;

            .product--category--container--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .product--category--container--content-title {
                    align-self: stretch;
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Headline/Display */

                    font-size: 72px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 96px;
                    /* 133.333% */
                }

                .product--category--container--content-subtitle {
                    align-self: stretch;
                    color: var(--Color-Text-medium-emphsis, #525257);

                    /* Body Text/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 0.32px;
                }
            }

            .product--category--container--filter {
                display: flex;
                padding: var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;

                .product--category--container--filter--selection {
                    display: flex;
                    width: 180px;
                    height: 180px;
                    padding: var(--Spacing-space-empty, 0px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-empty, 0px);

                    border-radius: var(--Radius-radius-xsss, 2px);
                    background: #FFF;
                    cursor: pointer;
                    transition: all .3s ease-in-out;

                    &:hover .product--category--container--filter--selection-description {
                        background: var(--Color-Base-base-black, #1D1D1F);

                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-wihte, #FFF);

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);

                            span {
                                align-self: stretch;
                                color: var(--Color-Text-high-emphasis, #121212);

                                /* Label/Medium */

                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 20px;
                                /* 142.857% */
                                letter-spacing: 0.14px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .product--category--container--filter--selection-image {
                        flex: 1 0 0;
                        align-self: stretch;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }


                    }



                    .product--category--container--filter--selection-description {
                        display: flex;
                        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-lg, 24px);
                        flex-direction: column;
                        align-items: flex-start;
                        gap: var(--Spacing-space-xss, 4px);
                        align-self: stretch;
                        background: #EDEDED;


                        span {
                            align-self: stretch;
                            color: var(--Color-Text-high-emphasis, #121212);

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                            text-transform: uppercase;
                        }

                        &.active {
                            background: var(--Color-Scheme-primary, #F1BE87);
                        }
                    }
                }
            }
        }

        .product--list {
            display: flex;
            padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            .product--list--title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                &.hidden {
                    opacity: 0;
                }

                .product--list--title--filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);
                    opacity: 1;
                    transition: all .1s ease-in-out;


                    .product--list--title--filter-title {
                        display: inline;
                        color: var(--Color-Text-high-emphasis, #121212);

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0.16px;
                    }

                    .product--list--title--filter-item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }

                    .mobile--product--list--title--filter-item {
                        display: none;
                    }
                }

                .product--list--right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .product--list--right-pagination {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        span {
                            color: var(--Color-Text-medium-emphsis, #525257);

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0.28px;
                        }
                    }

                    .product--list--right-sort {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .btn--sort {
                            display: flex;
                            padding: var(--Spacing-space-empty, 0px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-space-xss, 4px);

                            span {
                                color: var(--Color-Text-high-emphasis, #121212);
                                text-align: center;

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 16px;
                                letter-spacing: 0.12px;
                            }
                        }
                    }
                }
            }

            .product--list--container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                row-gap: 40px;
                column-gap: 16px;
                align-self: stretch;
                flex-wrap: wrap;
                width: 1360px;
                margin:0 auto;

                .product--list--container-item {
                    width: calc((1360px - 32px) / 3);
                }
            }


        }

        .product--pagination {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) 0px var(--Spacing-web-margin-L, 60px) 0px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
        }


    }

    .price--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .price--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            align-self: stretch;
            padding-top: var(--Spacing-space-m, 16px);
        }

        .price--filter--container-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            .container--label {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px var(--Spacing-space-s, 8px);
                align-self: stretch;
                flex-wrap: wrap;
            }


        }
    }

    .material--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .material--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .material--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            align-self: stretch;
            padding-top: var(--Spacing-space-m, 16px);
        }
    }

    .color--filter--container {
        display: flex;
        width: 600px;
        padding: var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);

        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Text-high-wihte, #FFF);

        /* Dropshadow 8 */
        box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

        .color--filter--container-content {
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 12px var(--Spacing-space-sm, 12px);
            align-self: stretch;
            flex-wrap: wrap;
        }

        .color--filter--container-button {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            padding-top: var(--Spacing-space-m, 16px);
            align-self: stretch;
        }
    }
}

.list--option--sort {
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 8px;
    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Text-high-wihte, #FFF);

    /* Dropshadow 8 */
    box-shadow: 0px 0px 2px 0px rgba(58, 56, 49, 0.14), 0px 4px 8px 0px rgba(58, 56, 49, 0.10);

    .option--sort {
        padding: 8px;
        color: var(--Color-Text-high-emphasis, #121212);
        text-align: left;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0.12px;

        &:hover {
            border-radius: var(--Radius-radius-xsss, 2px);
            background: var(--Color-Gun-sur-default-5, #F5F6F6);
        }

        &.active {
            border-radius: var(--Radius-radius-xsss, 2px);
            background: var(--Color-Gun-sur-default-5, #F5F6F6);
        }
    }
}