@import "./../../../src/variable.scss";

@media (max-width: 960px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  header {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    // max-width: 1440px;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    transition: all 0.3s linear;
    color: var(--Color-Text-high-wihte, #fff);
    z-index: 1000;

    span {
      &.button--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    span {
      &.tab--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    .hover-underline-animation {
      &:after {
        background-color: #fff;
      }
    }

    &.shrink {
      color: #000;
      height: 64px;

      span {
        &.button--name {
          color: #000;
        }
      }

      .main--menu {
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(12px);
        /* Dropshadow Widget 16 */
        box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
      }
    }

    .header--icon {
      width: 24px;
      height: 24px;

      position: relative;

      .qty-cart {
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        right: -4px;

        background-color: $high-emphasis;
        color: $high-white;
        font-size: 10px;
        line-height: 10px;
        font-weight: 600;
        border-radius: 50%;
      }
    }

    .main--menu {
      display: flex;
      padding: var(--Spacing-space-m, 16px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .main--menu--option-left {
        display: flex;
        align-items: center;
        gap: var(--Spacing-space-m, 16px);

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: none;
          }
        }
      }

      .header--logo {
        width: 56px;
        height: 24px;
        cursor: pointer;
      }

      .main--menu--option-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-m, 16px);

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: none;
          }
        }
      }
    }

    .sub.shrink {
      opacity: 0;
      // height: 0;
      // padding: 0;
      transform: translateY(-100%);
      box-sizing: border-box;
      z-index: -1;
    }

    .sub {
      display: none;
      padding: var(--Spacing-space-s, 8px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
      align-items: center;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;
      opacity: 1;
      transition: all 0.3s ease-in;
      color: #fff;

      .sub--menu--tab-left {
        display: flex;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .tab--name {
            /* Label/Label Text 16 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            /* 150% */
            letter-spacing: 0.16px;
          }
        }
      }

      .sub--menu--tab-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .tab--name {
            /* Label/Label Text 12 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            text-transform: capitalize;
            /* 133.333% */
            letter-spacing: 0.144px;
          }
        }
      }
    }

    &.changeColor {
      color: #000;

      span {
        &.button--name {
          color: #000;
        }
      }

      span {
        &.tab--name {
          color: #000;
        }
      }

      .hover-underline-animation {
        &:after {
          background-color: #000;
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    padding-bottom: 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);
    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    opacity: 1;
    transition: transform 0.8s ease-in-out;
    user-select: none;

    overflow-y: auto;

    .hover-underline-animation {
      &:after {
        background-color: #000;
      }
    }

    .menu--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-m, 16px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: #000;

      span {
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px;
      }
    }

    .menu--body {
      display: flex;
      padding: 0px var(--Spacing-space-m, 16px) var(--Spacing-space-lg, 24px)
        var(--Spacing-space-m, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      flex: 1 0 0;
      align-self: stretch;

      .category {
        display: flex;
        padding: 0px var(--Spacing-space-empty, 0px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-empty, 0px);
        align-self: stretch;
        // width: 100%;

        .menu--main {
          display: none;

          .menu--main--header {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            .menu--item {
              display: flex;
              padding: var(--Spacing-space-m, 16px)
                var(--Spacing-space-empty, 0px);
              align-items: center;
              gap: var(--Spacing-space-s, 8px);
              cursor: pointer;

              .menu--item--content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);

                .active {
                  &:after {
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: var(--Color-Text-high-emphasis, #121212);
                  }
                }

                .menu--item--content-name {
                  color: var(--Color-Text-high-emphasis, #121212);

                  /* Title/Large */

                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 30px;
                  text-transform: uppercase;
                  /* 150% */
                  letter-spacing: 0.2px;
                }
              }
            }
          }

          .menu--main--content {
            display: flex;
            height: 320px;
            align-items: flex-start;
            align-self: stretch;

            .sub.active {
              opacity: 1;
              z-index: 1;
            }

            .sub {
              position: absolute;
              display: flex;
              padding-right: var(--Spacing-space-lg, 24px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-empty, 0px);
              flex: 1 0 0;
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;

                    /* 150% */
                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .other--sub.active {
              opacity: 1;
              z-index: 1;
            }

            .other--sub {
              display: flex;
              padding-right: var(--Spacing-space-lg, 24px);
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .sub--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .sub--content-title {
                  color: var(--Color-Text-high-emphasis, #414549);

                  /* Title/Title Extra Large */

                  font-size: 36px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 48px;
                  /* 133.333% */
                }

                .sub--content-subtitle {
                  color: var(--Color-Text-medium-emphsis, #565e64);

                  /* Label/Medium */

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 142.857% */
                  letter-spacing: 0.14px;
                }
              }

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #414549);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;
                    /* 150% */
                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .block {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              overflow: hidden;

              img {
                object-fit: contain;
                width: 100%;
              }
            }

            .sub--block {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              overflow: hidden;

              img {
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }

        .menu--mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
          flex: 1 0 0;

          .menu--item {
            display: flex;
            padding: var(--Spacing-space-m, 16px)
              var(--Spacing-space-empty, 0px);
            justify-content: space-between;
            align-items: center;
            color: var(--Color-Text-high-emphasis, #414549);
            align-self: stretch;

            .menu--item--content {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;

              .menu--item--content-name {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.2px;
                text-transform: uppercase;
              }
            }
          }

          .menu--mobile--content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            height: 0px;
            overflow: hidden;
            transition: height 0.3s ease-in-out;

            &.active {
              height: 494px;
            }

            .sub {
              display: flex;
              padding: var(--Spacing-space-empty, 0px)
                var(--Spacing-space-lg, 24px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-empty, 0px);
              align-self: stretch;
              background: #f7f7f7;

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: 6px;
                align-self: stretch;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #414549);

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.16px;
                    text-transform: uppercase;
                  }
                }
              }
            }

            .other--sub {
              display: flex;
              padding: var(--Spacing-space-lg, 24px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-m, 16px);
              align-self: stretch;

              .other--sub--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .other--sub--content-title {
                  align-self: stretch;
                  color: var(--Color-Text-high-emphasis, #414549);

                  font-size: 24px;
                  font-style: normal;
                  font-weight: 800;
                  line-height: 36px;
                  letter-spacing: 0.12px;
                }

                .other--sub--content-subtitle {
                  align-self: stretch;
                  color: var(--Color-Text-medium-emphsis, #565e64);

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0.28px;
                }
              }
            }

            .block {
              display: flex;
              height: 300px;
              padding-top: var(--Spacing-space-s, 8px);
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: var(--Radius-radius-xsss, 2px);
              overflow: hidden;
              justify-content: center;

              img {
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }
      }

      .block--sub {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);
        align-self: stretch;

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          color: #121212;
        }
      }
    }
  }

  .menu.shrink {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
    // transition: opacity .3s ease-in-out .8s;
  }

  .search {
    position: absolute;
    top: 0;
    display: flex;
    left: 0;
    right: 0;
    height: 100vh;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);
    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    opacity: 1;
    transition: transform 0.8s ease-in-out;
    user-select: none;
    overflow-y: auto;

    &.shrink {
      transform: translateX(-100%);
      opacity: 0;
      transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
    }

    &--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-m, 16px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      span {
        color: #000;

        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px;
      }

      &--icon {
        color: #121212;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }

    &--body {
      display: flex;
      padding: 0px var(--Spacing-space-m, 16px) var(--Spacing-space-lg, 24px)
        var(--Spacing-space-m, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      flex: 1 0 0;
      align-self: stretch;

      &--category {
        display: flex;
        padding: 0px var(--Spacing-space-empty, 0px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
      }

      &--header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        justify-content: space-between;

        > span:first-child {
          color: var(--Color-Text-low-emphasis, #b3b3b7);

          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: 0.2px;
        }
      }

      &--result {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-s, 8px);
        width: 100%;
        flex-wrap: wrap;

        &-item {
          width: calc((100% - 16px)/2);
        }
      }
    }
  }
}

@media (min-width: 576px) {

  .search {
    position: absolute;
    top: 0;
    display: flex;
    left: 0;
    right: 0;
    height: 100vh;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);

    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    opacity: 1;
    transition: all 0.8s ease-in-out;
    user-select: none;
    overflow-y: auto;

    &.shrink {
      transform: translateY(-100%);
      opacity: 0;
    }

    &--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      span {
        color: #000;

        /* Headline/Headline 4 */

        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.12px;
      }

      &--icon {
        color: #121212;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }

    &--body {
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;

      &--category {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
      }

      &--header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        align-self: stretch;
        justify-content: space-between;

        > span:first-child {
          color: var(--Color-Text-low-emphasis, #b3b3b7);

          /* Headline/Headline 4 */

          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          /* 150% */
          letter-spacing: 0.12px;
        }
      }

      &--result {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-s, 8px);
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;

        &-item {
          width: calc((100% - 8px) / 2);
        }
      }
    }
  }
}

@media (min-width: 960px) {
  /* big landscape tablets, laptops, and desktops */
  header {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    max-width: 1440px;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    transition: all 0.3s linear;
    color: var(--Color-Text-high-wihte, #fff);
    z-index: 1000;

    span {
      &.button--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    span {
      &.tab--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    .hover-underline-animation {
      &:after {
        background-color: #fff;
      }
    }

    &.shrink {
      color: #000;
      height: 80px;

      span {
        &.button--name {
          color: #000;
        }
      }

      .main--menu {
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(12px);
        /* Dropshadow Widget 16 */
        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
        box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
      }
    }

    .header--icon {
      width: 32px;
      height: 32px;

      position: relative;

      .qty-cart {
        width: 16px;
        height: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0;
        right: 0;

        background-color: $high-emphasis;
        color: $high-white;
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        border-radius: 50%;
      }
    }

    .main--menu {
      display: flex;
      padding: var(--Spacing-space-m, 16px) 40px var(--Spacing-space-s, 8px)
        40px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .main--menu--option-left {
        display: flex;
        width: 380px;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        justify-content: left;

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: inline;
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
        }
      }

      .header--logo {
        width: 94px;
        height: 48px;
        cursor: pointer;
      }

      .main--menu--option-right {
        display: flex;
        width: 380px;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-m, 16px);

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: inline;
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
        }
      }
    }

    .sub.shrink {
      opacity: 0;
      // height: 0;
      // padding: 0;
      transform: translateY(-100%);
      box-sizing: border-box;
      z-index: -1;
    }

    .sub {
      display: flex;
      padding: var(--Spacing-space-s, 8px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
      align-items: center;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;
      opacity: 1;
      transition: all 0.3s ease-in;
      color: #fff;

      .sub--menu--tab-left {
        display: flex;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .active {
            &:after {
              position: absolute;
              width: 100%;
              transform: scaleX(1);
              height: 1px;
              bottom: 0;
              left: 0;
              background-color: var(--Color-Text-high-emphasis, #121212);
            }
          }

          .tab--name {
            /* Label/Label Text 16 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            /* 150% */
            letter-spacing: 0.16px;
          }
        }
      }

      .sub--menu--tab-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .active {
          &:after {
            position: absolute;
            width: 100%;
            transform: scaleX(1);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: var(--Color-Text-high-emphasis, #121212);
          }
        }

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .tab--name {
            /* Label/Label Text 12 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            text-transform: capitalize;
            /* 133.333% */
            letter-spacing: 0.144px;
          }
        }
      }
    }

    &.changeColor {
      color: #000;

      span {
        &.button--name {
          color: #000;
        }
      }

      span {
        &.tab--name {
          color: #000;
        }
      }

      .hover-underline-animation {
        &:after {
          background-color: #000;
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);
    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    transition: transform 0.8s ease-in-out;
    user-select: none;

    .hover-underline-animation {
      &:after {
        background-color: #000;
      }
    }

    .menu--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: #000;

      span {
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px;
      }
    }

    .menu--body {
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;

      .category {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
        width: 100%;

        .menu--mobile {
          display: none;
        }

        .menu--main {
          display: flex;
          align-items: flex-start;
          gap: var(--Spacing-space-xxl, 40px);
          flex: 1 0 0;
          align-self: stretch;

          .menu--main--header {
            display: flex;
            min-width: 200px;
            max-width: 200px;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-empty, 0px);
            box-sizing: border-box;

            .menu--item {
              display: flex;
              padding: var(--Spacing-space-m, 16px)
                var(--Spacing-space-empty, 0px);
              align-items: center;
              gap: var(--Spacing-space-s, 8px);
              cursor: pointer;

              .menu--item--content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);

                .active {
                  &:after {
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: var(--Color-Text-high-emphasis, #121212);
                  }
                }

                .menu--item--content-name {
                  color: var(--Color-Text-high-emphasis, #121212);

                  /* Title/Large */

                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 30px;
                  text-transform: uppercase;
                  /* 150% */
                  letter-spacing: 0.2px;
                }
              }
            }
          }

          .menu--main--content {
            display: flex;
            height: 320px;
            flex: 1 0 0;
            align-items: flex-start;
            align-self: stretch;

            .sub.active {
              opacity: 1;
              z-index: 1;
            }

            .sub {
              position: absolute;
              display: flex;
              max-width: 360px;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-empty, 0px);
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .active {
                    &:after {
                      position: absolute;
                      width: 100%;
                      transform: scaleX(1);
                      height: 1px;
                      bottom: 0;
                      left: 0;
                      background-color: var(
                        --Color-Text-high-emphasis,
                        #121212
                      );
                    }
                  }

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;
                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .other--sub.active {
              opacity: 1;
              z-index: 1;
            }

            .other--sub {
              display: flex;
              max-width: 200px;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: var(--Spacing-space-xxl, 40px);
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .sub--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .sub--content-title {
                  color: var(--Color-Text-high-emphasis, #414549);

                  /* Title/Title Extra Large */

                  font-size: 36px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 48px;
                  /* 133.333% */
                }

                .sub--content-subtitle {
                  color: var(--Color-Text-medium-emphsis, #565e64);

                  /* Label/Medium */

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 142.857% */
                  letter-spacing: 0.14px;
                }
              }

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #414549);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;

                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .block {
              display: flex;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              overflow: hidden;
              justify-content: center;

              img {
                object-fit: contain;
                width: 100%;
              }
            }

            .sub--block {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              overflow: hidden;
              justify-content: center;

              img {
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }
      }

      .block--sub {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);
        align-self: stretch;

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
        }
      }
    }
  }

  .menu.shrink {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
  }

  .search {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100vh;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);

    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    opacity: 1;
    transition: transform 0.8s ease-in-out;
    user-select: none;
    overflow-y: auto;

    &.shrink {
      transform: translateY(-100%);
      opacity: 0;
      transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
    }

    &--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      span {
        color: #000;

        /* Headline/Headline 4 */

        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.12px;
      }

      &--icon {
        color: #121212;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }

    &--body {
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;

      &--category {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
      }

      &--header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        align-self: stretch;
        justify-content: space-between;

        > span:first-child {
          color: var(--Color-Text-low-emphasis, #b3b3b7);

          /* Headline/Headline 4 */

          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          /* 150% */
          letter-spacing: 0.12px;
        }
      }

      &--result {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-lg, 24px);
        width: 100%;
        flex-wrap: wrap;

        &-item {
          width: calc((100% - 72px) / 3);
        }
      }
    }
  }
}

@media (min-width: 1441px) {
  /* hi-res laptops and desktops */
  header {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    max-width: 1920px;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    transition: all 0.3s linear;
    color: var(--Color-Text-high-wihte, #fff);
    z-index: 1000;

    span {
      &.button--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    span {
      &.tab--name {
        color: var(--Color-Text-high-wihte, #fff);
      }
    }

    .hover-underline-animation {
      &:after {
        background-color: #fff;
      }
    }

    &.shrink {
      color: #000;
      height: 80px;

      span {
        &.button--name {
          color: #000;
        }
      }

      .main--menu {
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(12px);
        /* Dropshadow Widget 16 */
        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
        box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
      }
    }

    .header--icon {
      width: 32px;
      height: 32px;
    }

    .main--menu {
      display: flex;
      padding: var(--Spacing-space-m, 16px) 40px var(--Spacing-space-s, 8px)
        40px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .main--menu--option-left {
        display: flex;
        width: 380px;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        justify-content: left;

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: inline;
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
        }
      }

      .header--logo {
        width: 94px;
        height: 48px;
        cursor: pointer;
      }

      .main--menu--option-right {
        display: flex;
        width: 380px;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-m, 16px);

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;
          user-select: none;

          .button--name {
            display: inline;
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
        }
      }
    }

    .sub.shrink {
      opacity: 0;
      // height: 0;
      // padding: 0;
      transform: translateY(-100%);
      box-sizing: border-box;
      z-index: -1;
    }

    .sub {
      display: flex;
      padding: var(--Spacing-space-s, 8px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
      align-items: center;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;
      opacity: 1;
      transition: all 0.3s ease-in;
      color: #fff;

      .sub--menu--tab-left {
        display: flex;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .active {
            &:after {
              position: absolute;
              width: 100%;
              transform: scaleX(1);
              height: 1px;
              bottom: 0;
              left: 0;
              background-color: var(--Color-Text-high-emphasis, #121212);
            }
          }

          .tab--name {
            /* Label/Label Text 16 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: uppercase;
            /* 150% */
            letter-spacing: 0.16px;
          }
        }
      }

      .sub--menu--tab-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-lg, 24px);
        flex: 1 0 0;

        .tab {
          display: flex;
          padding: var(--Spacing-space-s, 8px);
          align-items: center;
          gap: var(--Spacing-space-xs, 6px);
          cursor: pointer;
          user-select: none;

          .active {
            &:after {
              position: absolute;
              width: 100%;
              transform: scaleX(1);
              height: 1px;
              bottom: 0;
              left: 0;
              background-color: var(--Color-Text-high-emphasis, #121212);
            }
          }

          .tab--name {
            /* Label/Label Text 12 Semibold */
            font-family: var(--Font-Family-Label, Manrope);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            text-transform: capitalize;
            /* 133.333% */
            letter-spacing: 0.144px;
          }
        }
      }
    }

    &.changeColor {
      color: #000;

      span {
        &.button--name {
          color: #000;
        }
      }

      span {
        &.tab--name {
          color: #000;
        }
      }

      .hover-underline-animation {
        &:after {
          background-color: #000;
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    // height: 860px;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);
    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    transition: transform 0.8s ease-in-out;
    user-select: none;
    overflow-y: auto;

    .hover-underline-animation {
      &:after {
        background-color: #000;
      }
    }

    .menu--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: #000;

      span {
        font-size: 36px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px;
      }
    }

    .menu--body {
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;

      .category {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
        width: 100%;

        .menu--mobile {
          display: none;
        }

        .menu--main {
          display: flex;
          align-items: flex-start;
          gap: var(--Spacing-space-xxl, 40px);
          flex: 1 0 0;
          align-self: stretch;

          .menu--main--header {
            display: flex;
            min-width: 200px;
            max-width: 200px;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-empty, 0px);
            box-sizing: border-box;

            .menu--item {
              display: flex;
              padding: var(--Spacing-space-m, 16px)
                var(--Spacing-space-empty, 0px);
              align-items: center;
              gap: var(--Spacing-space-s, 8px);
              cursor: pointer;

              .menu--item--content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);

                .active {
                  &:after {
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: var(--Color-Text-high-emphasis, #121212);
                  }
                }

                .menu--item--content-name {
                  color: var(--Color-Text-high-emphasis, #121212);

                  /* Title/Large */

                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 30px;
                  text-transform: uppercase;
                  /* 150% */
                  letter-spacing: 0.2px;
                }
              }
            }
          }

          .menu--main--content {
            display: flex;
            height: 563px;
            align-items: flex-start;
            align-self: stretch;

            .sub.active {
              opacity: 1;
              z-index: 1;
            }

            .sub {
              position: absolute;
              display: flex;
              max-width: 360px;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-empty, 0px);
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .active {
                &:after {
                  position: absolute;
                  width: 100%;
                  transform: scaleX(1);
                  height: 1px;
                  bottom: 0;
                  left: 0;
                  background-color: var(--Color-Text-high-emphasis, #121212);
                }
              }

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;

                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .other--sub.active {
              opacity: 1;
              z-index: 1;
            }

            .other--sub {
              display: flex;
              max-width: 360px;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: var(--Spacing-space-xxl, 40px);
              align-self: stretch;
              opacity: 0;
              transition: all 0.8s ease-in-out;

              .sub--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .sub--content-title {
                  color: var(--Color-Text-high-emphasis, #414549);

                  /* Title/Title Extra Large */

                  font-size: 36px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 48px;
                  /* 133.333% */
                }

                .sub--content-subtitle {
                  color: var(--Color-Text-medium-emphsis, #565e64);

                  /* Label/Medium */

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  /* 142.857% */
                  letter-spacing: 0.14px;
                }
              }

              .menu--item {
                display: flex;
                height: 56px;
                padding: var(--Spacing-space-m, 16px)
                  var(--Spacing-space-empty, 0px);
                align-items: center;
                gap: var(--Spacing-space-xs, 6px);
                align-self: stretch;
                cursor: pointer;

                .menu--item--content {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: var(--Spacing-space-s, 8px);

                  .menu--item--content-name {
                    color: var(--Color-Text-high-emphasis, #414549);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: uppercase;

                    letter-spacing: 0.16px;
                  }
                }
              }
            }

            .block {
              display: flex;
              padding: var(--Spacing-space-empty, 0px);
              flex-direction: column;
              align-items: flex-start;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              height: 100%;
              overflow: hidden;
              justify-content: center;

              img {
                object-fit: contain;
                width: 100%;
              }
            }

            .sub--block {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: var(--Spacing-space-s, 8px);
              flex: 1 0 0;
              align-self: stretch;
              overflow: hidden;

              img {
                object-fit: contain;
                width: 100%;
              }
            }
          }
        }
      }

      .block--sub {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);
        align-self: stretch;

        .button {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
        }
      }
    }
  }

  .menu.shrink {
    transform: translateY(-100%);
    opacity: 0;
    transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
  }

  .search {
    position: absolute;
    top: 0;
    display: flex;
    left: 0;
    right: 0;
    // height: 860px;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);

    background: #fff;

    /* Dropshadow Widget 16 */
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
    z-index: 1000;
    opacity: 1;
    transition: transform 0.8s ease-in-out;
    user-select: none;
    overflow-y: auto;

    &.shrink {
      transform: translateY(-100%);
      opacity: 0;
      transition: transform 0.8s ease-in-out, opacity 0.3s ease-in-out 0.8s;
    }

    &--title {
      display: flex;
      padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      span {
        color: #000;

        /* Headline/Headline 4 */

        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 36px;
        /* 150% */
        letter-spacing: 0.12px;
      }

      &--icon {
        color: #121212;
        display: flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }
    }

    &--body {
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-xxl, 40px)
        var(--Spacing-space-lg, 24px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-lg, 24px);
      align-self: stretch;

      &--category {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;
      }

      &--header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        align-self: stretch;
        justify-content: space-between;

        > span:first-child {
          color: var(--Color-Text-low-emphasis, #b3b3b7);

          /* Headline/Headline 4 */

          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          /* 150% */
          letter-spacing: 0.12px;
        }
      }

      &--result {
        display: flex;
        align-items: flex-start;
        gap: var(--Spacing-space-lg, 24px);
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;

        &-item {
          width: calc((100% - 72px) / 4);
        }
      }
    }
  }
}
