body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-tabs {
  font-family: "Manrope", sans-serif;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
li,
label {
  font-family: "Manrope", sans-serif;
  line-height: 140%;
  margin: 0;
}

p {
  margin-bottom: 0;
}

video {
  vertical-align: top;
}

img {
  -webkit-user-drag: none;
}

.MuiModal-root {
  z-index: 9000 !important;
}

main::-webkit-scrollbar,
section::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 4px;
  /* Thanh cuộn dọc */
  height: 4px;
  /* Thanh cuộn ngang */
}

main::-webkit-scrollbar-track,
section::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0px;
}

main::-webkit-scrollbar-thumb,
section::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #1d1d1f;
  border-radius: 0px;
}

main::-webkit-scrollbar-thumb:hover,
section::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover {
  background-color: #333336;
}

.btn-main {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 8px 12px 8px 16px;
  border: none;
  border-radius: 2px;
  background: #f1be87;
  color: #121212;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-main:hover {
  background-color: #ab8760;
}

.btn-main-not-icon {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
  border: none;
  border-radius: 2px;
  background: #f1be87;
  color: #121212;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-main-not-icon:hover {
  background-color: #ab8760;
}

.btn-transprent {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 8px 12px 8px 16px;
  border: 1px solid #fff;
  border-radius: 2px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-transprent:hover {
  background-color: #fff;
  color: #121212;
}

.btn-transprent-not-icon {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 8px 24px;
  border: 1px solid #fff;
  border-radius: 2px;
  background: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-transprent-not-icon:hover {
  background-color: #fff;
  color: #121212;
}

.btn-white {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 8px 12px 8px 16px;
  border: 1px solid #121212;
  border-radius: 2px;
  background: #fff;
  color: #121212;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
}

.btn-white:hover {
  background-color: #121212;
  color: #fff;
}

.btn-white-not-icon {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px 24px;
  border: 1px solid #121212;
  border-radius: 2px;
  background: transparent;
  color: #121212;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-white-not-icon:hover {
  background-color: #121212;
  color: #fff;
}

.overlay-img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.22);
  z-index: 10;
}

.btn-underline {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  padding: 0;
  border-bottom: 2px solid transparent;
  background: transparent;
  color: #121212;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
}

.btn-underline--active {
  border-bottom: 1px solid #121212;
}

.btn-underline:hover {}

.no-scroll {
  overflow: hidden;
  /* position: fixed;
  width: 100%;
  top: 0; */
}

.btn-add,
.btn-sub {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  background: transparent;
  color: #121212;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
}

.btn-add:hover,
.btn-sub:hover {
  background-color: rgba(29, 29, 31, 0.12);
}

.black {
  color: #121212 !important;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.22);
  z-index: 500;
}

@media all and (min-width: 577px) and (max-width: 960px) {
  .loading-container {
    min-height: unset;
  }
}

@media all and (max-width: 576px) {
  .loading-container {
    min-height: unset;
    max-height: 265px;
  }
}


/* Firefox */
* {
  scrollbar-width: 4px;
  /* Thin scrollbar */
  scrollbar-color: #1d1d1f transparent;
  /* Thumb color, Track color */
}

/* WebKit Browsers */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  background: #E5E7E8;
  border-radius: 0px;
}