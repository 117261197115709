.notfound-page {
  margin-top: 128px;

  @media (max-width:1175px) {
    margin-top: 64px;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--background, #fff);
  position: relative;

  .notfound-section {
    height: 670px;
    background: #F7F7F7;
    width: 100%;
    position: relative;

    @media (max-width: 1175px) {
      height: 100vh;
    }

    .notfound-content {
      height: 100%;
      padding: 80px 40px 0px 40px;

      @media (max-width:577px) {
        padding: 40px 16px
      }

      .group-content {
        padding: 0px 40px;
        height: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;

        background-image: url("../../../public/assets/images/home/image404.png");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: contain;

        #imageNotFound{
          display: none;
        }

        @media (max-width: 1175px) {
          padding: 0px;
          background-position: bottom center;
          background-image: none;

          gap: 24px;

          #imageNotFound{
            display: block;
          }
        }

        .content--block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;
          align-self: stretch;

          @media (max-width: 1175px) {
            gap: 24px
          }
        }

        .notfound-title {
          color: var(--Color-Text-medium-emphsis, #525257);

          font-family: Manrope;
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 48px;

          @media (max-width: 577px) {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            letter-spacing: 0.2px;
          }
        }

        .text-content {
          color: var(--Color-Text-high-emphasis, #121212);

          font-family: Manrope;
          font-size: 48px;
          font-style: normal;
          font-weight: 800;
          line-height: 60px;

          @media (max-width: 577px) {
            color: var(--Color-Text-high-emphasis, #121212);

            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 42px;
          }
        }

        .notfound-subcontent {
          color: var(--Color-Text-medium-emphsis, #525257);

          font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.32px;

          @media (max-width: 577px) {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.28px;
          }
        }

        .button-back {
          display: flex;
          margin-top: 40px;
          padding: 10px var(--Spacing-space-lg, 24px);
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: var(--Radius-radius-xsss, 2px);
          background: var(--Color-Scheme-primary, #F1BE87);
          cursor: pointer;

          &:hover {
            // background-color: #0056b3;
          }
        }
      }
    }
  }
}