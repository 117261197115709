// styles/aboutUsPage.scss
@import "./../../../src/variable.scss";


.about-us-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .hero-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      position: absolute;
      left: 80px;
      bottom: 100px;

      @media (max-width: 577px) {
        left: 16px;
        bottom: 40px;
      }

      &--title {
        color: #FFF;

        font-size: 48px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px;

        @media (max-width: 577px) {
          font-size: 30px;
          line-height: 42px;
        }
      }

      &--content {
        color: #FFF;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;

        @media (max-width: 577px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.14px;
        }
      }
    }
  }

  .about-section {
    display: flex;
    padding: 60px var(--Spacing-space-xxl, 40px);
    flex-direction: column;
    gap: var(--Spacing-space-xxl, 40px);
    align-self: stretch;

    @media (max-width: 577px) {
      padding: 40px 16px;
      gap: 24px;
    }

    .about-title {
      color: var(--Color-Text-high-emphasis, #121212);

      font-family: Manrope;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;

      @media (max-width: 577px) {
        font-size: 30px;
        line-height: 42px;
      }
    }

    .about-content {
      color: var(--Color-Text-medium-emphsis, #525257);
      text-align: justify;

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.32px;
      padding: 0% 25%;

      @media (max-width: 577px) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.28px;
        padding: 0;
      }
    }

    .about-image {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        border-radius: var(--Radius-radius-xsss, 2px);
      }
    }
  }

  .feature-section {
    display: flex;
    padding: 60px var(--Spacing-space-xxl, 40px);
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-xxl, 40px);
    align-self: stretch;

    @media (max-width: 577px) {
      flex-direction: column;
      padding: 40px 16px;
      gap: 32px;
    }

    .group-content {
      display: flex;
      padding: var(--Spacing-space-empty, 0px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-xxl, 40px);
      flex: 1 0 0;

      @media (max-width: 577px) {
        gap: 24px;
        align-items: center;
      }
    }

    .feature-title {
      color: var(--Color-Text-high-emphasis, #121212);

      font-family: Manrope;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;

      @media (max-width: 577px) {
        font-size: 30px;
        line-height: 42px;
      }
    }

    .feature-content {
      color: var(--Color-Text-medium-emphsis, #525257);
      text-align: justify;

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.32px;

      @media (max-width: 577px) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.28px;
      }
    }

    .feature-items {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      justify-content: center;

      @media (max-width: 577px) {
        flex-direction: column;
        gap: 16px;
        align-self: stretch;
      }

      .feature-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        background: #f7f7f7;
        align-items: center;
        justify-content: center;
        flex: 1 0 0;
        padding: var(--Spacing-space-m, 16px);

        @media (max-width: 577px) {
          padding: 24px;
        }

        .icon {
          max-width: 64px;
          height: auto;
          margin-bottom: 1rem;
        }

        .item-title {
          font-size: 1.25rem;
          font-weight: bold;

          @media (max-width: 577px) {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: .16px;
          }
        }
      }
    }

    .feature-image {
      img {
        width: 100%;
        border-radius: 2px;
      }
    }

    .feature-button {
      display: flex;
      padding: 10px var(--Spacing-space-lg, 24px);
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: var(--Radius-radius-xsss, 2px);
      border: 1px solid var(--Outline-Default-outline-hover, #121212);
      color: #121212;
      cursor: pointer;

      &:hover {
        background-color: #fff;
      }
    }
  }

  .wood-workshop-section {
    display: flex;
    padding: 60px var(--Spacing-space-xxl, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-space-xxl, 40px);
    align-self: stretch;
    flex: 1 0 0;

    @media (max-width: 577px) {
      padding: 40px 16px;
      gap: 32px;
    }

    .workshop-title {
      color: var(--Color-Text-high-emphasis, #121212);

      font-family: Manrope;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;

      @media (max-width: 577px) {
        font-size: 30px;
        line-height: 42px;
      }
    }

    .workshop-content {
      color: var(--Color-Text-medium-emphsis, #525257);
      text-align: justify;

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.32px;
      padding: 0% 25%;

      @media (max-width: 577px) {
        padding: 0;
      }
    }

    .workshop-images {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;

      @media (max-width: 577px) {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
      }

      .workshop-image {
        max-width: calc(33.333% - 1rem);
        height: auto;
        border-radius: 8px;

        @media (max-width: 577px) {
          min-width: 100%;
        }
      }
    }

    .categories {
      &__swiper {
        width: 100%;

        &__name {
          color: $high-emphasis;
          font-size: 20px;
          font-weight: 700;
        }

        .swiper {
          padding-bottom: 60px;
          height: 520px;

          &:active {
            cursor: grabbing;
          }
        }

        .swiper-slide {
          height: 460px;
          border-radius: 8px;

          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          gap: 8px;

          background-color: transparent;
        }

        .swiper-button-prev,
        .swiper-button-next {
          &::after {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: bolder;
          }
        }

        .swiper-button-prev {
          display: none;
        }

        .swiper-button-next {
          display: none;
        }

        .swiper-scrollbar {
          width: 100%;
          height: 2px;
          position: absolute;
          top: 95%;
          left: 0;

          background-color: $low-emphasis;

          .swiper-scrollbar-drag {
            top: -200%;

            height: 6px;
            background-color: $high-emphasis;
            border-radius: 1px;
          }
        }
      }


    }
  }
}