.order-detail-wrapper {
  display: flex;
  justify-content: center;
  background-color: #f8f9fa;

  .order-detail {
    width: 100%;
    background: #fff;
    border-radius: 8px;

    .order-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 10px;

      .group-back {
        display: flex;
        flex-direction: row;
      }

      .back-button {
        background: none;
        border: none;
        color: #007bff;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          text-decoration: underline;
        }
      }

      .order-id {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      .order-status {
        font-size: 14px;
        color: #666;
      }
    }

    .timeline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      position: relative;

      .timeline-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        text-align: center;

        .timeline-circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #d3d3d3; /* Màu xám mặc định */
          border: 2px solid #d3d3d3; /* Đường viền mặc định */
          z-index: 1;
        }

        &.active .timeline-circle {
          background-color: #4caf50; /* Màu xanh lá cây khi kích hoạt */
          border-color: #4caf50;
        }

        .timeline-line {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%);
          width: calc(100% - 16px); /* Độ dài của đường nối */
          height: 2px;
          background-color: #d3d3d3; /* Màu xám mặc định */
          z-index: 0;
        }

        &.active + .timeline-step .timeline-line {
          background-color: #4caf50; /* Đường nối màu xanh nếu bước trước đó được kích hoạt */
        }

        .step-title {
          font-size: 14px;
          color: #333;
          margin-top: 8px;
        }

        .step-date {
          font-size: 12px;
          color: #666;
        }
      }
    }

    .details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .info-block {
        width: 48%;
        padding: 16px;
        gap: 24px;
        background: #f7f7f7;

        h2 {
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          margin: 5px 0;

          strong {
            font-weight: bold;
          }
        }
      }
    }

    .product-list {
      border-bottom: 2px solid #e0e0e0;
      margin-bottom: 16px;
      table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;

        thead {
          tr {
            th {
              text-align: left;
              padding: 10px 5px;
              border-bottom: 2px solid #e0e0e0;
              font-size: 14px;
            }
          }
        }

        tbody {
          tr {
            td {
              padding: 10px 5px;
              vertical-align: top;

              &.item-info {
                display: flex;
                align-items: center;

                img {
                  width: 80px !important;
                  height: 50px;
                  margin-right: 10px;
                }

                span {
                  font-size: 14px;
                }
              }

              .price {
                display: flex;
                flex-direction: column;

                .discount-price {
                  color: #ab253f;

                  font-family: Manrope;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  letter-spacing: 0.12px;
                }
              }

              .original-price {
                font-size: 12px;
                text-decoration: line-through;
                color: #999;
              }

              .discount-price {
                font-size: 14px;
                font-weight: bold;
                color: #333;
              }
            }
          }
        }
      }
    }

    .total {
      .group-text {
        border-bottom: 2px solid #e0e0e0;
        margin-bottom: 16px;
      }
      p {
        margin: 5px 0;
        font-size: 14px;
        display: flex;
        justify-content: space-between;

        strong {
          font-weight: bold;
        }
      }

      h3 {
        justify-content: space-between;
        display: flex;
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}
