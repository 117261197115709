.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .popup-content h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .popup-content p {
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .popup-close-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-close-btn:hover {
    background-color: #0056b3;
  }
  