.pagination {
    display: inline-flex;
    align-items: flex-start;
    gap: var(--Spacing-space-s, 8px);

    .btn--elipsis {
        pointer-events: none;
    }

    button.pagination--button {
        &:disabled {

            pointer-events: none;
            cursor: not-allowed;
            color: var(--Color-Text-Disable, #BBB);

            /* Body Text/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.28px;


        }
    }

    .pagination--button {
        display: flex;
        width: 32px;
        height: 32px;
        padding: var(--Spacing-space-xs, 6px) var(--Spacing-space-s, 8px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-sizing: border-box;



        .prev,
        next {
            color: var(--Color-Text-high-emphasis, #121212);

            display: flex;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
        }

        &.num {
            color: var(--Color-Text-high-emphasis, #121212);

            /* Body Text/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.28px;


        }

        &.active.num {
            border-bottom: 1px solid var(--Outline-Default-outline-click, #121212);

        }

        &:hover {
            &.num {
                border-bottom: 1px solid var(--Outline-Default-outline-click, #121212);
            }
        }
    }
}