@import "./../../../src/variable.scss";

.slider-scroll-mobile {
  display: none;
  .swiper {
    padding-bottom: 65px;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    width: 90%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      color: $high-emphasis;
      font-size: 14px;
      font-weight: bolder;
    }
  }

  .swiper-button-prev {
    display: none;
    position: absolute;
    left: 0;
    top: 90%;
  }

  .swiper-button-next {
    display: none;
    position: absolute;
    left: 50px;
    top: 90%;
  }

  .swiper-scrollbar {
    width: 100% !important;
    height: 2px !important;
    position: absolute !important;
    top: 95% !important;
    left: 0 !important;

    background-color: $low-emphasis;

    .swiper-scrollbar-drag {
      top: -200%;

      height: 6px;
      background-color: $high-emphasis;
      border-radius: 1px;
    }
  }
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: stretch;

  max-width: 1520px;
  margin: 0 auto;
  padding: 40px 0;
  column-gap: 16px;
  row-gap: 30px;

  &__item {
    width: calc((100% - 32px) / 3);
  }
}

.homepage {
  .hero-section {
    position: relative;

    &__swiper {
      img {
        object-fit: contain;
        background: rgba(0, 0, 0, 0.22);
      }

      &__content {
        position: absolute;
        bottom: calc(10% + 40px);
        left: 80px;

        display: flex;
        flex-direction: column;
        gap: $Spacing-space-lg;

        text-align: start;
        z-index: 100;

        &__title {
          color: $high-white;
          font-size: $Font-size-xl;
          text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.12);
          margin-bottom: 0;
        }

        &__text {
          color: $high-white;
          font-size: $Font-size-s;
          text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.12);
          margin-bottom: 0;
        }
      }

      .swiper-pagination {
        bottom: 6%;
      }

      .swiper-pagination-bullet {
        position: relative;
        width: 48px;
        height: 6px;
        background: transparent;
        border-radius: 2px;
        margin: 4px;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;

          width: 100%;
          height: 3px;
          border-radius: 0;

          background: $grey-color;
        }
      }

      .swiper-pagination-bullet-active {
        height: 6px;
        background: $high-emphasis;

        &::before {
          display: none;
        }
      }
    }
  }

  .categories {
    display: flex;
    flex-direction: column;
    gap: $Spacing-space-xxl;

    padding: 60px 40px 0 40px;

    &__top {
      text-align: center;

      &__heading {
        color: $high-emphasis;
        font-size: $Font-size-s;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &__title {
        color: $high-emphasis;
        font-size: $Font-size-xl;
        font-weight: 800;
        margin-bottom: 0;
      }
    }

    &__swiper {
      &__name {
        color: $high-emphasis;
        font-size: 20px;
        font-weight: 700;
      }
    }

    .swiper {
      padding-bottom: 100px;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-slide {
      width: 28%;

      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 8px;

      background-color: transparent;
      cursor: pointer;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: $high-emphasis;
        font-size: 14px;
        font-weight: bolder;
      }
    }

    .swiper-button-prev {
      position: absolute;
      left: 25%;
      top: 90%;
    }

    .swiper-button-next {
      position: absolute;
      left: calc(25% + 50px);
      top: 90%;
    }

    .swiper-scrollbar {
      width: calc(50% - 90px);
      height: 2px;
      position: absolute;
      top: 90%;
      left: calc(25% + 90px);

      background-color: $low-emphasis;

      .swiper-scrollbar-drag {
        top: -200%;

        height: 6px;
        background-color: $high-emphasis;
        border-radius: 1px;
      }
    }
  }

  .featured-products {
    display: flex;
    flex-direction: column;
    gap: $Spacing-space-xxl;

    padding: 60px 40px;

    &__top {
      text-align: center;

      &__heading {
        color: $high-emphasis;
        font-size: $Font-size-s;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &__title {
        color: $high-emphasis;
        font-size: $Font-size-xl;
        font-weight: 800;
        margin-bottom: 0;
      }
    }

    .swiper {
      padding-bottom: 100px;

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: $high-emphasis;
        font-size: 14px;
        font-weight: bolder;
      }
    }

    .swiper-button-prev {
      position: absolute;
      left: 25%;
      top: 90%;
    }

    .swiper-button-next {
      position: absolute;
      left: calc(25% + 50px);
      top: 90%;
    }

    .swiper-scrollbar {
      width: calc(50% - 90px);
      height: 2px;
      position: absolute;
      top: 90%;
      left: calc(25% + 90px);

      background-color: $low-emphasis;

      .swiper-scrollbar-drag {
        top: -200%;

        height: 6px;
        background-color: $high-emphasis;
        border-radius: 1px;
      }
    }
  }

  .video-section {
    position: relative;
    width: 100%;

    video {
      width: 100%;
    }

    .btn-play {
      position: absolute;
      bottom: 24px;
      right: 24px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 44px;
      height: 44px;
      background-color: rgba(255, 255, 255, 0.16);
      border-radius: 50%;

      &:hover {
        svg {
          scale: 1.2;
        }
      }
    }
  }

  .key-features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 60px 60px;
    background-color: $high-emphasis;

    &__show {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;
      max-width: 1520px;

      &__left {
        display: flex;
        flex-direction: column;

        width: 50%;
        padding-right: $Spacing-space-xl;

        &__heading {
          color: $high-white;
          font-size: $Font-size-s;
          font-weight: 600;
          margin-bottom: 0;
        }

        &__title {
          color: $high-white;
          font-size: $Font-size-xl;
          font-weight: 800;
          margin-bottom: 40px;
        }

        ol,
        ul {
          padding-left: 1rem;
          margin-bottom: 0;
        }

        &__content {
          color: $high-white;
          font-size: $Font-size-xs;
          font-weight: 500;
          margin-bottom: 40px;
        }
      }

      &__right {
        width: 50%;

        img {
          width: 100%;
        }
      }
    }

    &__hide {
      max-width: 1520px;
      display: flex;
      flex-direction: column;
      max-height: 0;
      overflow: hidden;

      transition: all ease-in 0.5s;

      .line {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.16);
        margin: $Spacing-space-xxl 0;
      }

      &__main {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $Spacing-space-m;

        &__item {
          // width: calc(100% - 16px);
          flex: 1 calc(50% - 8px);
        }
      }
    }
  }

  .key-features__hide--expanded {
    max-height: 1500px;
  }

  .key-details {
    background-color: #f7f7f7;

    display: flex;
    flex-direction: column;

    position: relative;
    padding-bottom: 40vw;
    z-index: 10;

    &__relative {
      padding: 60px 40px;

      background: linear-gradient(
        180deg,
        #f7f7f7 0%,
        #fff 72%,
        rgba(255, 255, 255, 0.69) 81%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &__text {
      &__heading {
        color: $high-emphasis;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      &__title {
        color: $high-emphasis;
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 24px;
      }

      &__content {
        color: $medium-emphasis;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }

    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      margin-bottom: 40px;
    }

    .key-details-container {
      display: flex;
      flex-direction: row;
      gap: $Spacing-space-s;

      max-width: 1520px;
      margin: 0 auto;

      &__card {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: $Spacing-space-m;

        background-color: $high-white;
        border-radius: $Radius-radius-xsss;
        padding: $Spacing-space-lg;
        text-align: center;

        &__header {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: $Spacing-space-s;

          font-size: $Font-size-s;
          font-weight: 700;

          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
        }

        video {
          width: 100%;
        }

        &__content {
          font-size: $Font-size-xs;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }

    .key-details-slider {
      display: none;
    }

    &__image {
      width: 100%;

      position: absolute;
      left: 0;
      bottom: 0;

      z-index: -1;
    }
  }

  .product-list-section {
    padding: 60px 40px;
  }

  .discount {
    max-width: 1520px;
    margin: 0 auto;
  }

  .intro-showroom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;

    max-width: 1520px;
    padding: 60px 40px;
    margin: 0 auto;

    background-color: #f7f7f7;

    &__image {
      width: 50%;
      object-fit: cover;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;

      height: 100%;
      // padding: 60px 40px;
      padding: 60px 0px;

      text-align: center;
      z-index: 100;

      &__title {
        color: $high-emphasis;
        font-size: 48px;
        font-weight: 800;
        margin-bottom: 32px;
      }

      &__text {
        color: $high-emphasis;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }
  }

  .inspire {
    display: flex;
    flex-direction: column;
    gap: $Spacing-space-xxl;

    padding: 60px 40px;

    &__top {
      text-align: center;

      &__heading {
        color: $high-emphasis;
        font-size: $Font-size-s;
        font-weight: 600;
      }

      &__title {
        color: $high-emphasis;
        font-size: $Font-size-xl;
        font-weight: 800;
      }

      &__content {
        color: $medium-emphasis;
        font-size: $Font-size-s;
        font-weight: 500;
      }
    }

    .swiper {
      padding-bottom: 100px;

      // &:hover {
      //   cursor: grab;
      // }

      &:active {
        cursor: grabbing;
      }
    }

    .swiper-slide {
      width: 28%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: $high-emphasis;
        font-size: 14px;
        font-weight: bolder;
      }
    }

    // .swiper-button-prev {
    //   position: absolute;
    //   left: 0;
    //   top: 90%;
    // }

    // .swiper-button-next {
    //   position: absolute;
    //   left: 50px;
    //   top: 90%;
    // }

    .swiper-button-prev {
      position: absolute;
      left: 25%;
      top: 90%;
    }

    .swiper-button-next {
      position: absolute;
      left: calc(25% + 50px);
      top: 90%;
    }

    .swiper-scrollbar {
      // width: calc(100% - 100px);
      // height: 2px;
      // position: absolute;
      // top: 90%;
      // left: 100px;

      width: calc(50% - 90px);
      height: 2px;
      position: absolute;
      top: 90%;
      left: calc(25% + 90px);

      background-color: $low-emphasis;

      .swiper-scrollbar-drag {
        top: -200%;

        height: 6px;
        background-color: $high-emphasis;
        border-radius: 1px;
      }
    }
  }
}

@media all and (min-width: 961px) and (max-width: 1280px) {
  .product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: stretch;

    max-width: 1520px;
    margin: 0 auto;
    padding: 40px 0;
    column-gap: 16px;
    row-gap: 30px;

    &__item {
      width: calc((100% - 16px) / 2);
    }
  }

  .homepage {
    .key-details {
      &__relative {
        padding: 24px 40px;
      }
    }

    .virtual {
      max-width: 1520px;
      margin: 0 auto;
      padding: 60px 40px;
    }

    .inspire {
      .swiper-slide {
        width: 31%;
        // width: 40%;
      }
    }
  }
}

@media all and (min-width: 577px) and (max-width: 960px) {
  .slider-scroll-mobile {
    display: block;
  }

  .product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: stretch;

    max-width: 1520px;
    margin: 0 auto;
    padding: 32px 0;
    column-gap: 16px;
    row-gap: 30px;

    &__item {
      width: calc((100% - 16px) / 2);
    }
  }

  .homepage {
    .hero-section {
      position: relative;

      &__swiper {
        img {
          background: rgba(0, 0, 0, 0.22);
        }

        &__content {
          position: absolute;
          bottom: calc(10% + 40px);
          left: 24px;

          display: flex;
          flex-direction: column;
          gap: $Spacing-space-xl;

          text-align: start;
          z-index: 100;

          &__title {
            color: $high-white;
            font-size: 36px;
          }

          &__text {
            color: $high-white;
            font-size: 14px;
          }
        }

        .swiper-pagination {
          bottom: 5%;
        }

        .swiper-pagination-bullet {
          position: relative;
          width: 24px;
          height: 4px;
          background: transparent;
          border-radius: 0;
          margin: 4px;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 2px;
            border-radius: 0;

            background: $grey-color;
          }
        }

        .swiper-pagination-bullet-active {
          height: 4px;
          background: $high-emphasis;

          &::before {
            display: none;
          }
        }
      }
    }

    .categories {
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xl;

      padding: 40px 16px 0 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }
      }

      &__swiper {
        &__name {
          color: $high-emphasis;
          font-size: 20px;
          font-weight: 700;
        }
      }

      .swiper {
        padding-bottom: 60px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 40%;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 8px;

        background-color: transparent;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        display: none;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 95%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }

    .featured-products {
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xxl;

      padding: 40px 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }
      }

      .swiper {
        padding-bottom: 80px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 65%;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 8px;

        background-color: transparent;
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 90%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }

    .key-features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 40px 16px;
      background-color: $high-emphasis;
    }

    .key-details {
      display: flex;
      flex-direction: column;

      position: relative;
      padding-bottom: 40vw;
      z-index: 10;

      &__relative {
        padding: 24px 40px;
      }

      &__text {
        &__heading {
          color: $high-emphasis;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 48px;
          font-weight: 800;
          margin-bottom: 24px;
        }

        &__content {
          color: $medium-emphasis;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 40px;
        }
      }

      .key-details-container {
        display: none;
      }

      .key-details-slider {
        display: block;

        .swiper {
          padding-bottom: 60px;

          &:active {
            cursor: grabbing;
          }
        }

        .swiper-slide {
          width: 334px;

          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          gap: 8px;

          background-color: transparent;

          .product--card {
            .product--card-image {
              height: 180px;
            }
          }
        }

        .swiper-scrollbar {
          width: 100%;
          height: 2px;
          position: absolute;
          top: 95%;
          left: 0;

          background-color: $low-emphasis;

          .swiper-scrollbar-drag {
            top: -200%;

            height: 6px;
            background-color: $high-emphasis;
            border-radius: 1px;
          }
        }
      }

      &__image {
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;

        z-index: -1;
      }
    }

    .product-list-section {
      padding: 40px 16px;
    }

    .intro-showroom {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: stretch;
      gap: 32px;

      max-width: 1520px;
      padding: 40px 16px;

      &__image {
        width: 100%;
        height: 278px;
        object-fit: cover;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;

        height: 100%;
        padding: 0;

        text-align: center;
        z-index: 100;

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        &__text {
          color: $high-emphasis;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 32px;
        }
      }
    }

    .virtual {
      max-width: 1520px;
      margin: 0 auto;
      padding: 40px 16px;

      .virtual-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap: $Spacing-space-lg;

        background-color: #fff;

        &__left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0;

          width: 100%;
          padding: 0;

          &__heading {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          &__title {
            color: $high-emphasis;
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 16px;
          }

          &__content {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 32px;
          }
        }

        &__right {
          width: 100%;
          padding: 0;

          video {
            width: 100%;
          }
        }
      }
    }

    .inspire {
      display: flex;
      flex-direction: column;
      gap: 0;

      padding: 40px 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        &__content {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 32px;
        }
      }

      .swiper {
        padding-bottom: 60px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 45%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        display: none;
        position: absolute;
        left: 0;
        top: 90%;
      }

      .swiper-button-next {
        display: none;
        position: absolute;
        left: 50px;
        top: 90%;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 90%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }
  }
}

@media all and (max-width: 576px) {
  .slider-scroll-mobile {
    display: block;
  }

  .product-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    align-items: stretch;

    max-width: 1520px;
    margin: 0 auto;
    padding: 32px 0;
    column-gap: 16px;
    row-gap: 30px;

    &__item {
      width: calc((100% - 16px) / 2);
    }
  }

  .homepage {
    .hero-section {
      position: relative;

      &__swiper {
        img {
          object-fit: cover;
          background: rgba(0, 0, 0, 0.22);
        }

        &__content {
          position: absolute;
          bottom: 80px;
          left: 16px;

          display: flex;
          flex-direction: column;
          gap: $Spacing-space-s;

          text-align: start;
          z-index: 100;

          &__title {
            color: $high-white;
            font-size: 36px;
          }

          &__text {
            color: $high-white;
            font-size: 14px;
            margin-bottom: 24px;
          }
        }

        .swiper-slide {
          height: 80vh;
        }

        .swiper-pagination {
          bottom: 5%;
        }

        .swiper-pagination-bullet {
          position: relative;
          width: 24px;
          height: 4px;
          background: transparent;
          border-radius: 0;
          margin: 4px;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;

            width: 100%;
            height: 2px;
            border-radius: 0;

            background: $grey-color;
          }
        }

        .swiper-pagination-bullet-active {
          height: 4px;
          background: $high-emphasis;

          &::before {
            display: none;
          }
        }
      }
    }

    .categories {
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xl;

      padding: 40px 16px 0 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }
      }

      &__swiper {
        &__name {
          color: $high-emphasis;
          font-size: 20px;
          font-weight: 700;
        }
      }

      .swiper {
        padding-bottom: 60px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 80%;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 8px;

        background-color: transparent;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        display: none;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 95%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }

    .featured-products {
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xxl;

      padding: 40px 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }
      }

      .swiper {
        padding-bottom: 80px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 65%;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 8px;

        background-color: transparent;

        .product--card {
          .product--card-image {
            height: 180px;
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 90%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }

    .video-section {
      position: relative;
      width: 100%;

      video {
        width: 100%;
      }

      .btn-play {
        display: none;
      }
    }

    .key-features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 40px 16px;
      background-color: $high-emphasis;

      &__show {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap: $Spacing-space-lg;

        width: 100%;
        max-width: 1520px;

        &__left {
          display: flex;
          flex-direction: column;
          gap: 0;

          width: 100%;
          padding-right: 0;

          &__heading {
            color: $high-white;
            font-size: 14px;
            font-weight: 600;
          }

          &__title {
            color: $high-white;
            font-size: 36px;
            font-weight: 800;
            margin-bottom: $Spacing-space-lg;
          }

          ol,
          ul {
            padding-left: 1rem;
            margin-bottom: 0;
          }

          button {
            display: none;
          }

          &__content {
            color: $high-white;
            font-size: $Font-size-xs;
            font-weight: 500;
          }
        }

        &__right {
          width: 100%;

          img {
            width: 100%;
          }
        }
      }

      &__hide {
        width: 100%;
        max-width: 1520px;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;

        transition: all ease-in 0.5s;

        .line {
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.16);
          margin: $Spacing-space-xxl 0;
        }

        &__main {
          display: block;
        }
      }

      .swiper-slide {
        width: 90%;
        background-color: $high-emphasis;
      }

      .swiper-scrollbar {
        width: 100% !important;
        height: 2px !important;
        position: absolute !important;
        top: 90% !important;
        left: 0 !important;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-white;
          border-radius: 1px;
        }
      }
    }

    .key-details {
      display: flex;
      flex-direction: column;

      position: relative;
      padding-bottom: 45vw;
      z-index: 10;

      &__relative {
        padding: 40px 16px;
      }

      &__text {
        &__heading {
          color: $high-emphasis;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        &__content {
          color: $medium-emphasis;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 40px;
        }
      }

      &__button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin-bottom: 0;
      }

      .key-details-container {
        display: none;
      }

      .key-details-slider {
        display: block;

        .swiper {
          padding-bottom: 60px;

          &:active {
            cursor: grabbing;
          }
        }

        .swiper-slide {
          width: 334px;

          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          gap: 8px;

          background-color: transparent;

          .product--card {
            .product--card-image {
              height: 180px;
            }
          }
        }

        .swiper-scrollbar {
          width: 100%;
          height: 2px;
          position: absolute;
          top: 95%;
          left: 0;

          background-color: $low-emphasis;

          .swiper-scrollbar-drag {
            top: -200%;

            height: 6px;
            background-color: $high-emphasis;
            border-radius: 1px;
          }
        }
      }

      &__image {
        width: 100%;

        position: absolute;
        left: 0;
        bottom: 0;

        z-index: -1;
      }
    }

    .product-list-section {
      padding: 40px 16px;
    }

    .intro-showroom {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: stretch;
      gap: 32px;

      max-width: 1520px;
      padding: 40px 16px;

      &__image {
        width: 100%;
        height: 278px;
        object-fit: cover;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;

        height: 100%;
        padding: 0;

        text-align: center;
        z-index: 100;

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        &__text {
          color: $high-emphasis;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 32px;
        }
      }
    }

    .virtual {
      max-width: 1520px;
      margin: 0 auto;
      padding: 40px 16px;

      .virtual-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap: $Spacing-space-lg;

        background-color: #fff;

        &__left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0;

          width: 100%;
          padding: 0;

          &__heading {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 4px;
          }

          &__title {
            color: $high-emphasis;
            font-size: 30px;
            font-weight: 800;
            margin-bottom: 16px;
          }

          &__content {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 32px;
          }
        }

        &__right {
          width: 100%;
          padding: 0;

          video {
            width: 100%;
          }
        }
      }
    }

    .inspire {
      display: flex;
      flex-direction: column;
      gap: 0;

      padding: 40px 16px;

      &__top {
        text-align: center;

        &__heading {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 4px;
        }

        &__title {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 16px;
        }

        &__content {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 32px;
        }
      }

      .swiper {
        padding-bottom: 60px;

        &:active {
          cursor: grabbing;
        }
      }

      .swiper-slide {
        width: 90%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        display: none;
        position: absolute;
        left: 0;
        top: 90%;
      }

      .swiper-button-next {
        display: none;
        position: absolute;
        left: 50px;
        top: 90%;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 90%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }
  }
}
