.blog--detail {
    display: flex;
    padding: 136px 40px 60px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    width: 100vw;

    @media (max-width: 577px) {
        padding: 96px 16px 40px 16px;
        gap: 40px;

        button {
            display: none;
        }
    }

    .blog--detail--content {
        display: flex;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;

        .blog--detail--content--left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            flex: 1 0 0;
            width: calc(100% - 442px);

            .blog--detail--content--left--title {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                h1 {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-family: Manrope;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 60px;
                    align-self: stretch;
                    text-align: justify;

                    @media (max-width: 577px) {
                        font-size: 30px;
                        line-height: 42px;
                    }
                }

                p {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-family: Manrope;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.32px;
                    align-self: stretch;
                    text-align: justify;

                    @media (max-width: 577px) {
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.32px;
                    }
                }

                img {
                    border-radius: var(--Radius-radius-xsss, 2px);
                    height: 440px;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .blog--detail--content--left--body {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                img {
                    width: 100%;
                }

                p {
                    color: var(--Color-Text-medium-emphsis, #525257);

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.28px;
                    text-align: justify;
                }

            }

            .blog--detail--content--left--tag {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px;
                align-self: stretch;
                flex-wrap: wrap;
            }
        }

        .blog--detail--content--right {
            display: flex;
            width: 442px;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;

            @media (max-width: 577px) {
                display: none;
            }
        }
    }
}