@import "./../../../src/variable.scss";

.modal-cart-in {
  transform: translateY(100%);
  transition: all ease-in-out 0.5s;
}
.modal-cart-in.modal-cart {
  z-index: 5000;
}

.modal-cart-out {
  transform: translateY(-100%);
  transition: all ease-in-out 0.5s;
}

.modal-cart {
  background-color: transparent;
  width: 100%;
  height: 100vh;

  position: fixed;
  top: -100%;
  left: 0;
  z-index: -1;

  &__close {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    background-color: transparent;
  }

  &__main {
    position: fixed;
    right: 40px;
    top: 65px;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 460px;
    height: fit-content;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    padding: 16px;
    z-index: 5000;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;

      &__title {
        font-size: 24px;
        font-weight: 800;
        margin-bottom: 0;
      }

      &__close {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border: none;
        border-radius: 50%;
        background: transparent;
        color: $high-emphasis;
        font-size: 14px;
        font-weight: 600;
        z-index: 2200;

        &:hover {
          background: rgba(29, 29, 31, 0.12);
        }
      }
    }

    &__body {
      width: 100%;
      border-top: 1px solid rgba(29, 29, 31, 0.16);
      margin-bottom: 24px;

      .card-cart {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        gap: 24px;

        background-color: #f7f7f7;
        padding: 16px;
        margin: 8px 0;

        &__image {
          width: 128px;
          height: auto;
          object-fit: contain;
        }

        &__info {
          display: flex;
          flex-direction: column;
          gap: 8px;

          &__name {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 600;
          }

          &__detail {
            display: flex;
            flex-direction: column;
            gap: 4px;

            &__text {
              color: $medium-emphasis;
              font-size: 12px;
              font-weight: 500;
            }
          }

          &__price {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: center;
            gap: 8px;

            &__org {
              color: $medium-emphasis;
              font-size: 12px;
              font-weight: 500;
              text-decoration: line-through;
            }

            &__sale {
              color: #ab253f;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }

    &__footer {
      &__submit {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;
        padding: 8px 12px 8px 16px;
        border: none;
        border-radius: 2px;
        background: #f1be87;
        color: #121212;
        font-size: 16px;
        line-height: 150%;
        font-weight: 600;

        &:hover {
          background-color: #ab8760;
        }
      }
    }
  }
}

@media all and (min-width: 577px) and (max-width: 1440px) {
}

@media all and (max-width: 960px) {
  .modal-cart-in {
    transform: translateY(-100%);
    transition: all ease-in-out 0.5s;
  }
  .modal-cart-in.modal-cart {
    z-index: 5000;
  }

  .modal-cart-out {
    transform: translateY(100%);
    transition: all ease-in-out 0.5s;
  }

  .modal-cart {
    background-color: transparent;
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 100%;
    left: 0;
    z-index: -1;

    &__close {
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 200%;
      background-color: transparent;
    }

    &__main {
      position: fixed;
      right: 0;
      top: unset;
      bottom: 0;

      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      width: 100%;
      height: fit-content;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
        2px -8px 22px 0px rgba(66, 66, 66, 0.05),
        0px 8px 8px 0px rgba(66, 66, 66, 0.07);
      padding: 16px;
      z-index: 5000;

      &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        &__title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0;
        }

        &__close {
          width: fit-content;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px;
          border: none;
          border-radius: 50%;
          background: transparent;
          color: $high-emphasis;
          font-size: 12px;
          font-weight: 600;
          z-index: 2200;

          &:hover {
            background: rgba(29, 29, 31, 0.12);
          }
        }
      }

      &__body {
        width: 100%;
        border-top: 1px solid rgba(29, 29, 31, 0.16);
        margin-bottom: 24px;

        .card-cart {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: start;
          gap: 24px;

          background-color: #f7f7f7;
          padding: 16px;
          margin: 8px 0;

          &__image {
            width: 128px;
            height: auto;
            object-fit: contain;
          }

          &__info {
            display: flex;
            flex-direction: column;
            gap: 8px;

            &__name {
              color: $high-emphasis;
              font-size: 16px;
              font-weight: 600;
            }

            &__detail {
              display: flex;
              flex-direction: column;
              gap: 4px;

              &__text {
                color: $medium-emphasis;
                font-size: 12px;
                font-weight: 500;
              }
            }

            &__price {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;
              gap: 8px;

              &__org {
                color: $medium-emphasis;
                font-size: 12px;
                font-weight: 500;
                text-decoration: line-through;
              }

              &__sale {
                color: #ab253f;
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }

      &__footer {
        &__submit {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 6px;
          padding: 8px 12px 8px 16px;
          border: none;
          border-radius: 2px;
          background: #f1be87;
          color: #121212;
          font-size: 16px;
          line-height: 150%;
          font-weight: 600;

          &:hover {
            background-color: #ab8760;
          }
        }
      }
    }
  }
}
