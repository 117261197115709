@import "./../../../src/variable.scss";

.cardCheckout {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 16px;

  background-color: #ffffff;
  padding: 16px;

  &__image {
    width: 40%;
    padding-top: 8px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 16px;

    &__name {
      color: $high-emphasis;
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 0;
    }

    &__option {
      color: $medium-emphasis;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &__price {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 8px;

      &__org {
        color: $medium-emphasis;
        font-size: 14px;
        font-weight: 500;
        text-decoration: line-through;
      }

      &__sale {
        color: #ab253f;
        font-size: 16px;
        font-weight: 600;
      }
    }

    &__qty {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 8px;

      &__number {
        min-width: 20px;
        text-align: center;
        color: $high-emphasis;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__remove {
    position: absolute;
    top: 16px;
    right: 16px;

    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border: none;
    border-radius: 50%;
    background: transparent;
    color: $high-emphasis;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background: rgba(29, 29, 31, 0.12);
    }
  }
}

@media all and (max-width: 960px) {
  .cardCheckout {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 16px;

    background-color: #ffffff;
    padding: 16px;

    &__image {
      width: 40%;
      padding-top: 4px;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    &__info {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      gap: 8px;

      &__name {
        color: $high-emphasis;
        font-size: 16px;
        font-weight: 700;
      }

      &__option {
        color: $medium-emphasis;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      &__price {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: 8px;

        &__org {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
          text-decoration: line-through;
        }

        &__sale {
          color: #ab253f;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &__qty {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 8px;

        &__number {
          min-width: 20px;
          text-align: center;
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    &__remove {
      position: absolute;
      top: 16px;
      right: 16px;

      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      padding: 8px;
      border: none;
      border-radius: 50%;
      background: transparent;
      color: $high-emphasis;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background: rgba(29, 29, 31, 0.12);
      }
    }
  }
}
