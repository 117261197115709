.mobile--filter--container {
    display: flex;
    width: 100vw;
    height: 100vh;
    padding: var(--Spacing-space-empty, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-empty, 0px);
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.10);
    overflow: auto;
    &--title {
        display: flex;
        padding: var(--Spacing-space-m, 16px);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        span {
            color: #000;
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 42px;
        }
    }

    &--body {
        display: flex;
        padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);
        flex: 1 0 0;
        align-self: stretch;

        &-category {
            display: flex;
            padding: 0px var(--Spacing-space-empty, 0px);
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            .filter {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                max-height: 32px;
                box-sizing: border-box;
                overflow-y: hidden;
                transition: all .3s ease-in-out;

                &.active {
                    max-height: 300px;

                    .icon {
                        transform: rotate(180deg);
                    }
                }

                &--menu--item {
                    display: flex;
                    padding: var(--Spacing-space-xss, 4px) var(--Spacing-space-empty, 0px);
                    justify-content: space-between;
                    align-items: center;
                    align-self: stretch;

                    &-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;

                        span {
                            color: var(--Color-Text-high-emphasis, #121212);

                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 24px;
                            letter-spacing: 0.16px;
                        }
                    }
                }

                &--sub {
                    display: flex;
                    padding: var(--Spacing-space-m, 16px) var(--Spacing-space-empty, 0px);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-m, 16px);
                    align-self: stretch;

                    .container--label {
                        display: flex;
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 16px var(--Spacing-space-m, 16px);
                        align-self: stretch;
                        flex-wrap: wrap;
                    }

                    .container--material {
                        display: flex;
                        padding-bottom: var(--Spacing-space-m, 16px);
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 12px var(--Spacing-space-sm, 12px);
                        align-self: stretch;
                        flex-wrap: wrap;
                    }

                    .container--color {
                        display: flex;
                        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-empty, 0px);
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 16px var(--Spacing-space-m, 16px);
                        align-self: stretch;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }

    &--footer {
        display: flex;
        padding: var(--Spacing-space-m, 16px);
        justify-content: flex-end;
        align-items: center;
        gap: var(--Spacing-space-m, 16px);
        align-self: stretch;
    }
}