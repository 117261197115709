.swiper {
  width: 100%;
  height: 100%;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fafafa;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-lazy-preloader {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform-origin: 0;
    box-sizing: border-box;
    border: none;
    margin-left: 0px;
    margin-top: 0px;
    border-radius: none;
  }
}

.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader, .swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader {
  animation: none;
}

.custom--swiper {

  .custom-prev-button,
  .custom-next-button {
    opacity: 0;
    background: none;
    border: none;
    font-size: 24px;
    /* adjust size as needed */
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    transition: opacity 0.2s ease-out;
  }

  .custom-prev-button {
    left: 8px;
    /* position to the left */
  }

  .custom-next-button {
    right: 8px;
    /* position to the right */
  }

  .custom-prev-button.swiper-button-disabled,
  .custom-next-button.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    z-index: 1000;
  }

  &:hover {

    .custom-prev-button,
    .custom-next-button {
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }

    .custom-prev-button.swiper-button-disabled,
    .custom-next-button.swiper-button-disabled {
      opacity: 0.35;
    }
  }
}