@import "./../../../src/variable.scss";

.cart--page {
  margin-top: 128px;

  @media (max-width: 577px) {
    .product--card .product--card-image {
      height: 180px;
    }
  }

  .swiper {
    padding-bottom: 80px;

    &:active {
      cursor: grabbing;
    }
  }

  .swiper-slide {
    width: 65%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8px;

    background-color: transparent;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-scrollbar {
    width: 100%;
    height: 2px;
    position: absolute;
    top: 90%;
    left: 0;

    background-color: $low-emphasis;

    .swiper-scrollbar-drag {
      top: -200%;

      height: 6px;
      background-color: $high-emphasis;
      border-radius: 1px;
    }
  }
  &--category {
    display: flex;
    padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-xl, 32px);
    align-self: stretch;
    background: #f7f7f7;

    &--container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--Spacing-space-xxl, 40px);
      align-self: stretch;

      &--content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--Spacing-space-xl, 32px);
        align-self: stretch;

        &--title {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-space-xl, 32px);
          align-self: stretch;

          span {
            align-self: stretch;
            color: var(--Color-Text-high-emphasis, #121212);
            text-align: center;

            font-size: 24px;
            font-style: normal;
            font-weight: 800;
            line-height: 36px;
            letter-spacing: 0.12px;
          }
        }
      }
    }
  }
}

.cart-product {
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 60px 40px;
  background: #f7f7f7;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__title {
      color: var(--Color-Text-high-emphasis, #121212);
      font-size: 48px;
      font-weight: 800;
    }

    &__qty {
      color: var(--Color-Text-medium-emphsis, #525257);
      font-size: 14px;
      font-weight: 500;
    }

    &__btn-back {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      padding: 4px 0;
      margin-bottom: 0;
      border: none;
      background: transparent;
      color: var(--Color-Text-high-emphasis, #121212);
      font-size: 14px;
      line-height: 150%;
      font-weight: 600;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 40px;

    &__list-card {
      width: 60%;

      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__summary {
      width: calc(40% - 40px);

      display: flex;
      flex-direction: column;
      gap: 32px;

      padding: 32px;
      background-color: #fff;

      &__title {
        color: $high-emphasis;
        font-size: 24px;
        font-weight: 800;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(29, 29, 31, 0.16);
      }

      .delivery-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0;

        &__left {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
        }

        &__right {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 500;
          text-align: right;
        }
      }

      .total-temp {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__text {
          color: $high-emphasis;
          font-size: 18px;
          font-weight: 700;
        }

        &__number {
          color: $high-emphasis;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .mobile-submit {
        position: relative;

        &__price {
          display: none;

          &__text {
            color: $high-emphasis;
            font-size: 18px;
            font-weight: 700;
          }

          &__number {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 600;
          }
        }

        &__button {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 6px;
          padding: 8px 12px 8px 16px;
          border: none;
          border-radius: 2px;
          background: #f1be87;
          color: #121212;
          font-size: 16px;
          line-height: 150%;
          font-weight: 600;

          &:hover {
            background-color: #ab8760;
          }
        }
      }

      .discount-code {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__title {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
        }

        &__button {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .discount-input {
        display: flex;
        flex-direction: row;
        gap: 8px;
        margin-top: 24px;
        height: 0;
        overflow: hidden;
        transition: all ease-in-out 0.3s;

        &__group {
          position: relative;
          flex: 1 0;

          input {
            width: 100%;
            background-color: transparent;
            border: none;
            border-radius: 0px;
            outline: none;
            border-bottom: 1px solid
              var(--Outline-Default-outline-filled, #121212);
            padding: 10px 20px 10px 0;
          }

          button {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        &__btn {
          width: fit-content;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          padding: 8px 16px;
          border: 1px solid #121212;
          border-radius: 2px;
          background: #fff;
          color: #121212;
          font-size: 14px;
          line-height: 150%;
          font-weight: 600;

          &:hover {
            background-color: #121212;
            color: #fff;
          }
        }
      }

      .discount-input-show.discount-input {
        height: 45px;
      }

      .list-visaCard {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

@media all and (max-width: 960px) {
  .cart--page {
    margin-top: 56px;

    &--category {
      display: flex;
      padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Spacing-space-xl, 32px);
      align-self: stretch;
      background: #f7f7f7;

      &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;

        &--content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Spacing-space-xl, 32px);
          align-self: stretch;

          &--title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: var(--Spacing-space-xl, 32px);
            align-self: stretch;

            span {
              align-self: stretch;
              color: var(--Color-Text-high-emphasis, #121212);
              text-align: center;

              font-size: 24px;
              font-style: normal;
              font-weight: 800;
              line-height: 36px;
              letter-spacing: 0.12px;
            }
          }
        }
      }
    }
  }

  .cart-product {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 40px 16px;
    background: #f7f7f7;

    &__header {
      display: flex;
      flex-direction: column-reverse;
      justify-content: start;
      align-items: start;

      &__title {
        color: $high-emphasis;
        font-size: 30px;
        font-weight: 800;
      }

      &__qty {
        color: $medium-emphasis;
        font-size: 14px;
        font-weight: 500;
      }

      &__btn-back {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        padding: 4px 0;
        margin-bottom: 8px;
        border: none;
        background: transparent;
        color: var(--Color-Text-high-emphasis, #121212);
        font-size: 14px;
        line-height: 150%;
        font-weight: 600;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 40px;

      &__list-card {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__summary {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 16px;

        padding: 16px;
        background-color: #fff;

        &__title {
          color: $high-emphasis;
          font-size: 24px;
          font-weight: 800;
        }

        .line {
          width: 100%;
          height: 1px;
          background-color: rgba(29, 29, 31, 0.16);
        }

        .delivery-container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 2px 0;

          &__left {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
          }

          &__right {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .total-temp {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &__text {
            color: $high-emphasis;
            font-size: 18px;
            font-weight: 700;
          }

          &__number {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .mobile-submit {
          width: 100%;
          position: fixed;
          bottom: 0;
          right: 0;

          background: #fff;
          box-shadow: 0px 0px 16px 0px rgba(58, 56, 49, 0.1);
          padding: 16px;
          z-index: 2000;

          &__price {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;

            &__text {
              color: $high-emphasis;
              font-size: 16px;
              font-weight: 600;
            }

            &__number {
              color: $high-emphasis;
              font-size: 16px;
              font-weight: 700;
            }
          }

          &__button {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 6px;
            padding: 8px 12px 8px 16px;
            border: none;
            border-radius: 2px;
            background: #f1be87;
            color: #121212;
            font-size: 16px;
            line-height: 150%;
            font-weight: 600;

            &:hover {
              background-color: #ab8760;
            }
          }
        }

        .discount-code {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &__title {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
          }

          &__button {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }
        }

        .list-visaCard {
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}
