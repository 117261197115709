@import "./../../../src/variable.scss";

.modal-full {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  width: 100vw;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
    2px -8px 22px 0px rgba(66, 66, 66, 0.05),
    0px 8px 8px 0px rgba(66, 66, 66, 0.07);
  padding: $Spacing-space-lg;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-bottom: $Spacing-space-lg;

    &__close {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      padding: 8px;
      border: none;
      border-radius: 50%;
      background: transparent;
      color: $high-emphasis;
      font-size: 14px;
      font-weight: 600;
      z-index: 2200;

      &:hover {
        background: rgba(29, 29, 31, 0.12);
      }
    }
  }

  &__body {
    width: 100%;
    flex-grow: 1;

    &__slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;

      &__number {
        position: absolute;
        bottom: 30px;
        left: 40px;
        transform: translateY(50%);

        font-size: 14px;
        color: $medium-emphasis;
        padding: 2px 12px;
        background-color: transparent;
        z-index: 100;
      }

      .swiper {
        padding-bottom: 60px;

        img {
          object-fit: contain;
        }

        .swiper-slide {
          height: 100vh;
        }

        // Pagination
        .swiper-pagination {
          left: 50%;
          bottom: 30px;
          transform: translate(-50%, 50%);
          padding: 0 50px 0 0;
          z-index: 10;
        }

        .swiper-pagination-bullet {
          width: 48px;
          height: 6px;
          background: transparent;
          border-radius: 0;
          margin: 4px;
          opacity: 1;
        }

        .swiper-pagination-bullet-active-prev,
        .swiper-pagination-bullet-active-next {
          transform: scale(0.6, 0.4);
          border-radius: 0;
          background: #cdd1d4;
        }

        .swiper-pagination-bullet-active-main {
          transform: scale(1, 0.4);
          border-radius: 0;
          background: #808b90;
        }

        .swiper-pagination-bullet-active-prev-prev,
        .swiper-pagination-bullet-active-next-next {
          transform: scale(0);
          border-radius: 0;
          background: transparent;
        }

        .swiper-pagination-bullet-active {
          transform: scale(1);
          background: $high-emphasis;
          border-radius: 2px;
        }
        // Pagination

        // Prev, Next
        .swiper-button-prev,
        .swiper-button-next {
          &::after {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: bolder;
          }
        }

        .swiper-button-prev {
          position: absolute;
          left: calc(100% - 140px);
          top: calc(100% - 30px);
        }

        .swiper-button-next {
          position: absolute;
          right: 60px;
          top: calc(100% - 30px);
        }
        // Prev, Next
      }
    }
  }
}

@media all and (min-width: 577px) and (max-width: 1440px) {
}

@media all and (max-width: 576px) {
  .modal-full {
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    padding: $Spacing-space-lg;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: center;
      padding-bottom: $Spacing-space-lg;

      &__close {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        padding: 8px;
        border: none;
        border-radius: 50%;
        background: transparent;
        color: $high-emphasis;
        font-size: 14px;
        font-weight: 600;
        z-index: 2200;

        &:hover {
          background: rgba(29, 29, 31, 0.12);
        }
      }
    }

    &__body {
      width: 100%;
      flex-grow: 1;

      &__slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        &__number {
          position: absolute;
          bottom: 30px;
          left: calc(50% + 60px);
          transform: translateY(50%);

          font-size: 14px;
          color: $medium-emphasis;
          padding: 2px 12px;
          background-color: transparent;
          z-index: 100;
        }

        .swiper {
          padding-bottom: 60px;

          img {
            object-fit: contain;
          }

          .swiper-slide {
            height: 100vh;
          }

          // Pagination
          .swiper-pagination {
            left: calc(50% - 30px);
            bottom: 30px;
            transform: translate(-50%, 50%);
            padding: 0 50px 0 0;
            z-index: 10;
          }

          .swiper-pagination-bullet {
            width: 24px;
            height: 4px;
            background: transparent;
            border-radius: 0;
            margin: 4px;
            opacity: 1;
          }

          .swiper-pagination-bullet-active-prev,
          .swiper-pagination-bullet-active-next {
            transform: scale(0.6, 0.4);
            border-radius: 0;
            background: #cdd1d4;
          }

          .swiper-pagination-bullet-active-main {
            transform: scale(1, 0.4);
            border-radius: 0;
            background: #808b90;
          }

          .swiper-pagination-bullet-active-prev-prev,
          .swiper-pagination-bullet-active-next-next {
            transform: scale(0);
            border-radius: 0;
            background: transparent;
          }

          .swiper-pagination-bullet-active {
            transform: scale(1);
            background: $high-emphasis;
            border-radius: 2px;
          }
          // Pagination

          // Prev, Next
          .swiper-button-prev,
          .swiper-button-next {
            display: none;
          }
          // Prev, Next
        }
      }
    }
  }
}
