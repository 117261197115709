@media (min-width:320px) {
    .mobile--search--page {
        &--category {
            &-result {
                color: var(--Color-Text-low-emphasis, #B3B3B7);

                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.2px;
            }
        }
    }

    .search--page {

        display: flex;
        padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-m, 16px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-m, 16px);
        flex: 1 0 0;
        align-self: stretch;

        display: flex;
        margin-top: 64px;


        &--category {
            display: flex;
            padding: 0px var(--Spacing-space-empty, 0px);
            justify-content: center;
            flex-direction: column;
            align-items: start;
            gap: var(--Spacing-space-m, 16px);
            align-self: stretch;

            &-result {
                display: none;
            }
        }


        &--tab {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
        }

        &--content--result {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-lg, 24px);
            align-self: stretch;

            &-title {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                .mobile--product--list--title--filter-item {
                    display: flex;
                }

                .filter {
                    display: none;
                }
            }

            &-list {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;
                flex-direction: column;

                .result--list-item {
                    width: calc(100%);
                }
            }
        }
    }
}

@media (min-width:576px) {
    .mobile--search--page {
        &--category {
            &-result {
                display: none;
            }
        }
    }

    .search--page {
        display: flex;
        // height: 814px;
        margin-top: 128px;
        padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;

        &--category {
            display: flex;
            padding: 0px var(--Spacing-space-empty, 0px);
            justify-content: center;
            flex-direction: column;
            align-items: start;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            &-result {
                display: inline;
                color: var(--Color-Text-low-emphasis, #B3B3B7);

                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.2px;

                b {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.2px;
                }
            }
        }


        &--tab {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
        }

        &--content--result {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-lg, 24px);
            align-self: stretch;

            &-title {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                .mobile--product--list--title--filter-item {
                    display: none;
                }

                .filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }
                }
            }

            &-list {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .result--list-item {
                    width: calc((100% - 8px) / 2);
                }
            }
        }
    }
}

@media (min-width:1025px) {
    .mobile--search--page {
        &--category {
            &-result {
                display: none;
            }
        }
    }

    .search--page {
        display: flex;
        // height: 814px;
        margin-top: 128px;
        padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;

        &--category {
            display: flex;
            padding: 0px var(--Spacing-space-empty, 0px);
            justify-content: center;
            flex-direction: column;
            align-items: start;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            &-result {
                display: inline;
                color: var(--Color-Text-low-emphasis, #B3B3B7);

                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.2px;

                b {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.2px;
                }
            }
        }


        &--tab {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
        }

        &--content--result {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-lg, 24px);
            align-self: stretch;

            &-title {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                .mobile--product--list--title--filter-item {
                    display: none;
                }

                .filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }
                }
            }

            &-list {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .result--list-item {
                    width: calc((100% - 32px) / 3);
                }
            }
        }
    }
}

@media (min-width:1441px) {
    .mobile--search--page {
        &--category {
            &-result {
                display: none;
            }
        }
    }

    .search--page {
        display: flex;
        // height: 814px;
        margin-top: 128px;
        padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-xxl, 40px);
        align-self: stretch;

        &--category {
            display: flex;
            padding: 0px var(--Spacing-space-empty, 0px);
            justify-content: center;
            flex-direction: column;
            align-items: start;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;

            &-result {
                display: inline;
                color: var(--Color-Text-low-emphasis, #B3B3B7);

                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0.2px;

                b {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    letter-spacing: 0.2px;
                }
            }
        }


        &--tab {
            display: flex;
            align-items: flex-start;
            gap: var(--Spacing-space-m, 16px);
        }

        &--content--result {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-lg, 24px);
            align-self: stretch;

            &-title {
                display: flex;
                align-items: center;
                gap: 24px;
                align-self: stretch;

                .mobile--product--list--title--filter-item {
                    display: none;
                }

                .filter {
                    display: flex;
                    align-items: center;
                    gap: var(--Spacing-space-m, 16px);

                    .item {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);
                    }
                }
            }

            &-list {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;
                flex-direction: row;
                flex-wrap: wrap;

                .result--list-item {
                    width: calc((100% - 72px) / 4);
                }
            }
        }
    }
}