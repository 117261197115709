$heightHeader: 90px;
$maxWidth: 1920px;

.main {
  // max-width: $maxWidth;
  margin: 0 auto;
  background: #fff;

  .body {
  }

  .footer {
    padding: 40px 100px;

    .title {
      font-size: 50px;
    }

    .slogan {
      color: #000;
      font-size: 25px;
      font-weight: 500;
      margin-top: 20px;
    }

    form {
      width: 50%;
      height: 90%;
      display: flex;
      flex-wrap: wrap;
      background-color: #000;
      border-radius: 20px;
      padding: 10px;

      .box-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }

      span {
        color: #252525;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 2px;
      }

      input,
      textarea {
        border-radius: 5px;
        border: 1px solid rgba(179, 179, 179, 0.5);
        background: #000;
        color: #252525;
        padding: 4px 10px;
        resize: none;

        &::placeholder {
          font-style: italic;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .main {
    .body {
      // padding: 60px 0 60px;
    }
  }
}
