.address-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .hero-section {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      position: absolute;
      left: 80px;
      bottom: 100px;

      @media (max-width: 577px) {
        left: 16px;
        bottom: 40px;
      }

      &--title {
        color: #FFF;

        font-size: 48px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px;

        @media (max-width: 577px) {
          font-size: 30px;
          line-height: 42px;
        }
      }

      &--content {
        color: #FFF;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;

        @media (max-width: 577px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.14px;
        }
      }
    }
  }

  .locations-section {
    display: flex;
    padding: 60px var(--Spacing-space-xxl, 40px);
    align-items: flex-start;
    gap: var(--Spacing-space-xxl, 40px);
    align-self: stretch;

    @media (max-width: 577px) {
      padding: 40px 0px;
      gap: 32px;
    }

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;

      @media (max-width: 577px) {
        font-size: 30px;
        line-height: 42px;
      }
    }

    .locations-container {
      display: flex;
      width: 100vw;
      gap: 40px;
      flex-direction: row;

      @media (max-width: 577px) {
        gap: 32px;
        flex-direction: column;
      }

      .locations-left {
        width: 25%;

        @media (max-width: 577px) {
          width: 100%;
        }

        .group-icon {
          gap: 8px;
        }

        .location-icon {
          width: 24px;
          height: 24px;
          margin-bottom: 16px;
        }

        .radio-group {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .radio-item {
            display: flex;
            padding: var(--Spacing-space-m, 16px);
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;
            border: 2px solid transparent;
            cursor: pointer;
            transition: all 0.3s ease;

            &.selected {
              border-radius: var(--Radius-radius-xsss, 2px);
              border: 1px solid var(--Outline-Default-outline-click, #121212);
              background: #f7f7f7;
            }

            input[type="radio"] {
              display: none;
              /* Ẩn nút radio mặc định */
            }

            h3 {
              color: var(--Color-Text-high-emphasis, #121212);

              font-family: Manrope;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              letter-spacing: 0.18px;
            }

            p {
              color: var(--Color-Text-medium-emphsis, #525257);

              font-family: Manrope;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0.16px;
            }

            /* Icon và text */
            // &::before {
            //   content: "";
            //   display: block;
            //   width: 24px;
            //   height: 24px;
            //   margin-bottom: 8px;
            //   background-size: contain;
            //   background-repeat: no-repeat;
            //   background-position: center;
            // }

            /* Văn bản */
            span {
              font-size: 1rem;
              color: #333;
            }

            /* Hiệu ứng khi chọn */
            input[type="radio"]:checked+& {
              border-color: #007bff;
              background-color: #e6f0ff;
              box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
            }
          }

          .group-radio-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
          }
        }
      }

      .locations-right {
        width: 75%;

        @media (max-width: 577px) {
          width: 100%;
          position: relative;
          left: 0;
          right: 0;
        }
      }

      .location-item {
        flex: 1 1 calc(33.333% - 2rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #f9f9f9;
        padding: 1rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .location-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .location-icon {
            width: 24px;
            height: 24px;
            margin-bottom: 1rem;
          }

          h3 {
            font-size: 1.2rem;
            font-weight: bold;
            color: #333;
          }

          p {
            font-size: 1rem;
            color: #666;
            margin-top: 0.5rem;
          }
        }



        .location-image {
          margin-top: 1rem;

          img {
            width: 100%;
            border-radius: 8px;
          }
        }
      }
    }
  }
}