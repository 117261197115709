.chip--suggest {
    display: inline-flex;
    padding: var(--Spacing-space-xs, 6px) var(--Spacing-space-sm, 12px);
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Gun-sur-default-5, #F5F6F6);

    .chip--suggest-title {
        color: var(--Color-Text-medium-emphsis, #525257);

        /* Label/Medium */

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.14px;
    }

    &:hover {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Gun-sur-default-10, #E5E7E8);

        .chip--suggest-title {
            color: var(--Color-Text-medium-emphsis, #525257);

            /* Label/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.14px;
        }
    }

    &.selected {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Gun-sur-default-10, #E5E7E8);

        .chip--suggest-title {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
        }
    }
}

.chip--suggest--icon {
    display: inline-flex;
    padding: var(--Spacing-space-xs, 6px) var(--Spacing-space-s, 8px);
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-space-xs, 6px);

    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Gun-sur-default-5, #F5F6F6);

    .chip--suggest--icon-item {
        width: 20px;
        height: 20px;
    }

    .chip--suggest--icon-title {
        color: var(--Color-Text-medium-emphsis, #525257);

        /* Label/Medium */

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.14px;
    }

    &:hover {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Gun-sur-default-10, #E5E7E8);

        .chip--suggest--icon-title {
            color: var(--Color-Text-medium-emphsis, #525257);

            /* Label/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.14px;
        }
    }

    &.selected {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Gun-sur-default-10, #E5E7E8);

        .chip--suggest--icon-title {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
        }
    }


}