.account-settings {
  display: flex;
  margin-top: 128px;
  gap: 60px 40px;
  padding: 40px;
  background-color: #f8f8f8;

  &__title {
    display: none;
  }

  &__sidebar {
    flex: 1;
    background: #ffffff;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    p {
      color: var(--Color-Text-medium-emphsis, #525257);

      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.28px;
      margin-bottom: 40px;
    }

    &-menu {
      flex-direction: column;
      display: flex;
      gap: 24px;
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 40px;

      li {
        width: fit-content;
        display: flex;
        align-items: start;
        color: var(--Color-Text-high-emphasis, #121212);
        text-align: center;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;
        cursor: pointer;

        &:hover,
        &.active {
          border-bottom: solid 1px #121212;
        }
      }
    }
  }

  &__divider {
    border: none;
    border-top: 1px solid rgba(29, 29, 31, 0.16); /* Đường kẻ màu xám nhạt */
    background: rgba(29, 29, 31, 0.16);
    margin: 16px 0;
  }

  &__logout-btn {
    display: block;
    margin-top: 16px;
    color: #ff4d4f;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--Color-Text-high-emphasis, #121212);
    text-align: center;

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  &__delete-btn {
    color: var(--Color-Scheme-error, #e05364);
    text-align: center;

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  &__form {
    flex: 3;
    background: #ffffff;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 768px) {
  .account-settings {
    margin-top: 56px !important;
    display: flow;
    padding: 0;


    
  }

  .account-settings__title {
    display: contents;
    color: var(--Color-Text-high-emphasis, #121212);

    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0.12px;
    margin-top: 40px !important;
    margin-bottom: 32px !important;
  }

  .account-settings__sidebar-title,
  .account-settings__sidebar-description,
  .account-settings__divider,
  .account-settings__logout-btn,
  .account-settings__delete-btn {
    display: none;
  }

  .account-settings {
    &__sidebar {
      flex: 1;
      background: #ffffff;
      padding: 0px;
      border-radius: 0px;
      box-shadow: 0 0px 0px;

      // &-title {
      //   font-size: 20px;
      //   font-weight: bold;
      //   margin-bottom: 16px;
      // }
      // p {
      //   color: var(--Color-Text-medium-emphsis, #525257);

      //   font-family: Manrope;
      //   font-size: 14px;
      //   font-style: normal;
      //   font-weight: 500;
      //   line-height: 20px;
      //   letter-spacing: 0.28px;
      //   margin-bottom: 40px;
      // }

      &-menu {
        flex-direction: row;
      }
    }

    // &__divider {
    //   border: none;
    //   border-top: 1px solid rgba(29, 29, 31, 0.16); /* Đường kẻ màu xám nhạt */
    //   background: rgba(29, 29, 31, 0.16);
    //   margin: 16px 0;
    // }

    // &__logout-btn {
    //   display: block;
    //   margin-top: 16px;
    //   color: #ff4d4f;
    //   background: none;
    //   border: none;
    //   cursor: pointer;
    //   color: var(--Color-Text-high-emphasis, #121212);
    //   text-align: center;

    //   font-family: Manrope;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 600;
    //   line-height: 24px;
    //   letter-spacing: 0.16px;
    // }

    // &__delete-btn {
    //   color: var(--Color-Scheme-error, #e05364);
    //   text-align: center;

    //   font-family: Manrope;
    //   font-size: 16px;
    //   font-style: normal;
    //   font-weight: 600;
    //   line-height: 24px;
    //   letter-spacing: 0.16px;
    // }

    // &__form {
    //   flex: 3;
    //   background: #ffffff;
    //   padding: 32px;
    //   border-radius: 8px;
    //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // }
  }
}
