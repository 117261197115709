@import "./../../../src/variable.scss";

.swiper-scroll {
  display: flex;
  flex-direction: column;
  gap: $Spacing-space-xxl;

  padding: 60px 40px;

  &__title {
    h2 {
      color: $high-emphasis;
      font-size: 36px;
      font-weight: 700;
    }
  }

  &__main {
    .swiper {
      padding-bottom: 100px;
    }

    .swiper-slide {
      width: 30%;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: $high-emphasis;
        font-size: 14px;
        font-weight: bolder;
      }
    }

    .swiper-button-prev {
      position: absolute;
      left: 0;
      top: 90%;
    }

    .swiper-button-next {
      position: absolute;
      left: 50px;
      top: 90%;
    }

    .swiper-scrollbar {
      width: calc(100% - 100px);
      height: 2px;
      position: absolute;
      top: 90%;
      left: 100px;

      background-color: $low-emphasis;

      .swiper-scrollbar-drag {
        top: -200%;

        height: 6px;
        background-color: $high-emphasis;
        border-radius: 1px;
      }
    }
  }
}

@media all and (max-width: 960px) {
  .swiper-scroll {
    display: flex;
    flex-direction: column;
    gap: $Spacing-space-xxl;

    padding: 40px 16px 10px 16px;

    &__title {
      h2 {
        color: $high-emphasis;
        font-size: 30px;
        font-weight: 700;
      }
    }

    &__main {
      .swiper {
        padding-bottom: 80px;
      }

      .swiper-slide {
        width: 90%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        display: none;
      }

      .swiper-button-next {
        display: none;
      }

      .swiper-scrollbar {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 90%;
        left: 0;

        background-color: $low-emphasis;

        .swiper-scrollbar-drag {
          top: -200%;

          height: 6px;
          background-color: $high-emphasis;
          border-radius: 1px;
        }
      }
    }
  }
}
