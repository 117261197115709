@media (min-width: 320px) {
  .product--card {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-s, 8px);
    flex: 1 0 0;
    border-radius: var(--Radius-radius-xsss, 2px);
    background-color: white;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      .hover-underline-animation {
        &:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }

    .swiper {
      padding-bottom: 0 !important;
    }

    .swiper-slide {
      div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .product--card-image {
      position: relative;
      display: flex;
      // height: 106px;
      aspect-ratio: 3 / 2;
      justify-content: center;
      align-items: flex-start;
      flex-shrink: 0;
      align-self: stretch;

      background-color: #fafafa;
      border-radius: var(--Radius-radius-xsss, 2px);

      overflow: hidden;
    }

    .product--card--info {
      position: relative;
      display: flex;
      padding: 0px var(--Spacing-space-empty, 0px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-xs, 6px);
      align-self: stretch;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: var(--Color-Text-high-emphasis, #121212);

        .product--card--info-name {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;

          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;

            color: var(--Color-Text-high-emphasis, #121212);

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.14px;
          }
        }
      }

      &--detail {
        display: flex;
        padding: 0px var(--Spacing-space-empty, 0px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-s, 8px);
        align-self: stretch;

        &-category {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--Spacing-space-xs, 6px);
          align-self: stretch;

          span {
            // min-height: 28px;
            flex: 1 0 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            color: var(--Color-Text-medium-emphsis, #525257);

            font-family: Manrope;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.1px;
          }

          .color {
            display: flex;
            align-items: center;
            gap: var(--Spacing-space-xss, 4px);

            .rect {
              width: 10px;
              height: 10px;
            }

            span {
              color: var(--Color-Text-high-emphasis, #121212);

              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: 10px;
              /* 180% */
              letter-spacing: 0.2px;
            }
          }
        }

        &-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: var(--Spacing-space-empty, 0px);
          align-self: stretch;
          height: 30px;
          box-sizing: border-box;

          .original {
            color: var(--Color-Text-high-emphasis, #121212);

            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.24px;
          }

          .sale {
            display: flex;
            align-items: center;
            gap: 4px;

            .content {
              color: #ab253f;

              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              letter-spacing: 0.24px;
            }
          }
        }

        &-price.product--card--info--detail-sale {
          .original {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.1px;
            text-decoration-line: line-through;
          }
        }

        &-tag {
          display: flex;
          align-items: flex-start;
          gap: var(--Spacing-space-xss, 4px);
          flex-direction: row;
          flex-wrap: wrap;
          align-self: stretch;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .product--card {
    .product--card-image {
      // height: 200px;
    }
  }
}

@media (min-width: 1025px) {
  .product--card {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-space-s, 8px);
    flex: 1 0 0;
    border-radius: var(--Radius-radius-xsss, 2px);
    background-color: white;
    box-sizing: border-box;

    &:hover {
      .hover-underline-animation {
        &:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }

    .product--card-image {
      position: relative;
      display: flex;
      // height: 295px;
      justify-content: center;
      align-items: flex-start;
      flex-shrink: 0;
      align-self: stretch;

      background-color: #fafafa;
      border-radius: var(--Radius-radius-xsss, 2px);

      overflow: hidden;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .product--card--info {
      position: relative;
      display: flex;
      padding: var(--Spacing-space-empty, 0px) var(--Spacing-space-s, 8px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-s, 8px);
      align-self: stretch;

      &-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: var(--Color-Text-high-emphasis, #121212);

        .product--card--info-name {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-s, 8px);
          cursor: pointer;

          span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.16px;
          }
        }
      }

      &--detail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--Spacing-space-xss, 4px);
        align-self: stretch;

        &-category {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: var(--Spacing-space-lg, 24px);
          align-self: stretch;

          span {
            flex: 1 0 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            color: var(--Color-Text-medium-emphsis, #525257);
            text-align: left;

            /* Body Text/Small */

            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
            letter-spacing: 0.24px;
          }

          .color {
            display: flex;
            align-items: start;
            gap: var(--Spacing-space-xss, 4px);

            .rect {
              width: 10px;
              height: 10px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
              }
            }

            span {
              color: var(--Color-Text-high-emphasis, #121212);

              font-size: 10px;
              font-style: normal;
              font-weight: 700;
              line-height: 10px;
              /* 180% */
              letter-spacing: 0.2px;
            }
          }
        }

        &-price {
          display: flex;
          align-items: center;
          gap: var(--Spacing-space-xss, 4px);
          align-self: stretch;
          flex-direction: row;
          justify-content: left;

          .original {
            color: var(--Color-Text-high-emphasis, #121212);

            /* Body Text/Small */

            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 150% */
            letter-spacing: 0.24px;
          }

          .sale {
            display: flex;
            align-items: center;
            gap: var(--Spacing-space-xss, 4px);

            .content {
              /* Body Text/Small */

              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              /* 150% */
              letter-spacing: 0.24px;
              color: #cf3e56;
            }
          }
        }

        &-price.product--card--info--detail-sale {
          .original {
            text-decoration: line-through;
          }
        }

        &-tag {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          flex-wrap: wrap;
          gap: var(--Spacing-space-xss, 4px);
          align-self: stretch;
        }
      }
    }
  }
}

@media (min-width: 1441px) {
}
