.input--container {
  position: relative;
  flex: 1 0 0;
  display: flex;
  align-self: stretch;

  .icon {
    position: absolute;
    top: calc(50% - 8px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    right: 0px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  .icon--expand {
    position: absolute;
    top: calc(50% - 8px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    right: 0px;
    opacity: 1;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  .icon--error {
    position: absolute;
    top: calc(50% - 8px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    right: 0px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    color: var(--Outline-stroke-error, #f08b79);
  }

  .pos--icon {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    color: #525257;
  }

  .pos--icon ~ input {
    padding: 10px 0 10px 32px;
  }
}

input {
  display: inline-flex;
  padding: 10px var(--Spacing-space-empty, 0px);
  align-items: center;
  gap: var(--Spacing-space-s, 8px);
  background-color: transparent;
  flex: 1 0 0;

  color: var(--Color-Text-high-emphasis, #121212);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;

  box-sizing: border-box;
  height: 40px;

  &::placeholder {
    color: var(--Color-Text-medium-emphsis, #525257);

    /* Body Text/Medium */

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.28px;
  }

  &:not(:placeholder-shown) {
    border: none;
    border-bottom: 1px solid var(--Outline-Default-outline-default, #121212);
    outline: none;
  }

  &:not(:placeholder-shown) ~ .icon {
    opacity: 1;
  }

  &:placeholder-shown {
    border: none;
    border-bottom: 1px solid
      var(--Outline-Default-outline-default, rgba(18, 18, 18, 0.16));
    outline: none;
  }

  &:disabled {
    border: none;
    border-bottom: 1px solid var(--Outline-stroke-disable, #bbb);
    background: var(--Color-Gun-sur-disable, #ebebeb);
    outline: none;
  }

  &:disabled::placeholder {
    color: var(--Color-Text-Disable, #bbb);

    /* Body Text/Medium */

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.28px;
  }

  // &:invalid {
  //     border: none;
  //     border-bottom: 1px solid var(--Outline-stroke-error, #F08B79);
  //     outline: none;
  // }

  // &:invalid~.icon--error {
  //     opacity: 0;
  // }

  &[readonly] {
    cursor: pointer;
  }
}

.show--error {
  input,
  select {
    &:invalid ~ .icon--error {
      opacity: 1;
    }
    &:invalid {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid var(--Outline-stroke-error, #f08b79);
      outline: none;
    }
  }
}

select {
  display: inline-flex;
  padding: 10px var(--Spacing-space-empty, 0px);
  align-items: center;
  gap: var(--Spacing-space-s, 8px);
  background-color: transparent;
  flex: 1 0 0;

  color: var(--Color-Text-medium-emphsis, #525257);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;

  box-sizing: border-box;
  height: 40px;

  border: none;
  border-bottom: 1px solid
    var(--Outline-Default-outline-default, rgba(18, 18, 18, 0.16));
  outline: none;

  &:disabled {
    border: none;
    border-bottom: 1px solid var(--Outline-stroke-disable, #bbb);
    background: var(--Color-Gun-sur-disable, #ebebeb);
    outline: none;
  }

  &.selected {
    color: var(--Color-Text-high-emphasis, #121212);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    box-sizing: border-box;
    height: 40px;
    border-bottom: 1px solid var(--Outline-Default-outline-default, #121212);
  }

  &[readonly] {
    cursor: pointer;
  }
}

.container--slider {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  .container--slider-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    span {
      color: var(--Color-Text-medium-emphsis, #525257);

      /* Body Text/Small */

      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      /* 133.333% */
      letter-spacing: 0.24px;
    }
  }

  .wrapper--input--range {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;

    .container--input--range {
      position: relative;
      width: 100%;
      height: 24px;

      input[type="range"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        outline: none;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        background-color: transparent;
        pointer-events: none;
        border: none;
        padding: 0;
      }

      .slider-track {
        width: 100%;
        height: 2px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 4px;
        border-radius: 5px;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        height: 2px;
      }

      input[type="range"]::-moz-range-track {
        -moz-appearance: none;
        height: 2px;
      }

      input[type="range"]::-ms-track {
        appearance: none;
        height: 2px;
      }

      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 16px;
        width: 16px;
        background-color: #1d1d1f;
        cursor: pointer;
        margin-top: -9px;
        pointer-events: auto;
        border-radius: 50%;
      }

      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 16px;
        width: 16px;
        cursor: pointer;
        border-radius: 50%;
        background-color: #1d1d1f;
        pointer-events: auto;
        border: none;
      }

      input[type="range"]::-ms-thumb {
        appearance: none;
        height: 16px;
        width: 16px;
        cursor: pointer;
        border-radius: 50%;
        background-color: #1d1d1f;
        pointer-events: auto;
      }

      input[type="range"]:active::-webkit-slider-thumb {
        background-color: #ffffff;
        border: 1px solid #1d1d1f;
      }
    }
  }
}

.values {
  background-color: #1d1d1f;
  width: 32%;
  position: relative;
  margin: auto;
  padding: 10px 0;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
}

.values:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-top: 15px solid #1d1d1f;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin: auto;
  bottom: -14px;
  left: 0;
  right: 0;
}

.input--checkbox {
  display: flex;
  align-items: center;
  gap: 8px;

  .input--title {
    color: var(--Color-Text-medium-emphsis, #525257);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
  }

  input {
    &[type="checkbox"] {
      width: 18px;
      height: 18px;
      padding: 0;
      appearance: none;

      border-radius: 1px;
      border: 1px solid
        var(--Outline-Default-outline-default, rgba(18, 18, 18, 0.16));
      background: var(--Color-Base-base-white, #fff);
    }

    &[type="checkbox"]:hover {
      border-radius: 1px;
      border: 1px solid var(--Outline-Default-outline-click, #121212);
      background: var(--Color-Base-base-white, #fff);
    }

    &[type="checkbox"]:checked {
      border-radius: 1px;
      border: 1px solid var(--Color-Base-base-black, #1d1d1f);
      background-color: var(--Color-Base-base-black, #1d1d1f);
      background-image: url(../../assets/images/check_small.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    &[type="checkbox"]:checked:hover {
      border-radius: 1px;
      border: 1px solid var(--Color-Text-medium-emphsis, #525257);
      background-color: var(--Color-Text-medium-emphsis, #525257);
      background-image: url(../../assets/images/check_small.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }

    &[type="checkbox"]:disabled {
      border-radius: 1px;
      border: 1px solid var(--Outline-Default-outline-disable, #bbb);
      background: var(--Color-Gun-sur-disable, #ebebeb);
    }

    &[type="checkbox"]:disabled ~ .input--title {
      color: var(--Color-Text-Disable, #bbb);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.07px;
    }

    &[type="checkbox"]:checked:disabled {
      border-radius: 1px;
      background: var(--Color-Gun-sur-disable, #ebebeb);
    }
  }
}
