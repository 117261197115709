.faq--page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hero_faq {
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        align-self: stretch;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;
            position: absolute;
            left: 80px;
            bottom: 100px;

            @media (max-width: 577px) {
                left: 16px;
                bottom: 40px;
            }

            &--title {
                color: #FFF;

                font-size: 48px;
                font-style: normal;
                font-weight: 800;
                line-height: 60px;

                @media (max-width: 577px) {
                    font-size: 30px;
                    line-height: 42px;
                }
            }

            &--content {
                color: #FFF;

                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.16px;

                @media (max-width: 577px) {
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.14px;
                }
            }
        }
    }

    .faq-section {
        padding: 60px 40px;
        gap: 40px;
        display: flex;
        flex-direction: column;

        @media (max-width:577px) {
            padding: 40px 16px;
            gap: 24px;
        }

        .faq-title {
            color: var(--Color-Text-high-emphasis, #121212);

            font-family: Manrope;
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 60px;

            @media (max-width:577px) {
                font-size: 24px;
                line-height: 36px;
                letter-spacing: 0.12px;
            }
        }

        ul.faq-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            gap: 16px;
            display: flex;
            flex-direction: column;

            @media (max-width:577px) {
                gap: 0px;
            }
        }

        .faq-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            align-self: stretch;
            padding: 16px;

            .faq-question {
                color: var(--Color-Text-high-emphasis, #121212);

                font-family: Manrope;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0.18px;

                @media (max-width:577px) {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 28px;
                    letter-spacing: 0.18px;
                }
            }

            .faq-answer {
                color: var(--Color-Text-medium-emphsis, #525257);

                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0.28px;

                @media (max-width:577px) {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.28px;
                }
            }


        }
    }
}


.layer-blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.22);
}