.change-password-title {
  color: var(--Color-Text-high-emphasis, #121212);

  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.12px;
}

.change-password-label {
  color: #000;

  /* Label/Medium */
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  margin-bottom: 8px;
}

.change-password-input {
  display: flex;
  margin-bottom: 40px !important;
  padding: 10px 0px;
  width: 100% !important;
  align-items: center;
  gap: var(--Spacing-space-s, 8px);
  align-self: stretch;
  border-bottom: 1px solid
    var(--Outline-Default-outline-default, rgba(18, 18, 18, 0.16)) !important;
}

.change-password-input:focus {
//   border-color: #ff9d4d;
//   outline: none;
//   box-shadow: 0 0 4px rgba(255, 157, 77, 0.5);
}

.change-password-button {
  width: 30%;
  display: flex;
  padding: 10px var(--Spacing-space-lg, 24px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Radius-radius-xsss, 2px);
  background: var(--Color-Scheme-primary, #f1be87);

  color: var(--Color-Text-high-emphasis, #121212);
  text-align: center;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.change-password-button:hover {
  border-radius: var(--Radius-radius-xsss, 2px);
  background: var(--Color-Scheme-primary-hover, #ab8760);
}

.button-cancel {
  width: 30% !important;
  background-color: transparent;
  color: #333;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: calc(50% - 5px);

  color: var(--Color-Text-high-emphasis, #121212);
  text-align: center;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.button-cancel:hover {
  border-radius: var(--Radius-radius-xsss, 2px);
  background: var(--Color-Base-base-black, #1d1d1f);
  color: #ffffff;
}

.button-group {
  display: flex;
  justify-content: start;
  margin-top: 10px;
}

.instruction-text {
  color: var(--Color-Text-medium-emphsis, #525257);

  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  margin-bottom: 40px;
}

.error-message{
    color: var(--Color-Scheme-error, #E05364);

/* Body Text/S-small */
font-family: Manrope;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 14px; /* 140% */
letter-spacing: 0.1px;
}