.policy-page {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;

  .hero-section {
    text-align: center;
    position: relative;

    .group-content {
      position: absolute;
      bottom: 100px;
      left: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Spacing-space-s, 8px);

      @media (max-width:577px) {
        bottom: 40px;
        padding: 0px 16px;
        right: 0;
        left: 0;
      }
    }

    .hero-title {
      color: var(--Color-Text-high-wihte, #fff);

      font-family: Manrope;
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
      line-height: 60px;

      @media (max-width:577px) {
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 42px;
      }
    }

    .hero-subtitle {
      color: #fff;

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.16px;

      @media (max-width:577px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.14px;
      }
    }

    .hero-image {
      img {
        width: 100%;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      @media (max-width:577px) {
        display: flex;
        align-items: center;
        height: 100vh;
        overflow: hidden;

        img {
          width: 100%;
          height: 100vh;
          object-fit: cover;
        }
      }
    }
  }

  .policy-section {
    display: flex;
    padding: 60px var(--Spacing-space-xxl, 40px);
    align-items: flex-start;
    gap: var(--Spacing-space-xxl, 40px);
    align-self: stretch;

    @media (max-width:577px) {
      padding: 24px 16px 40px 16px;
    }

    .policy-container {
      display: flex;
      width: 100%;
      overflow: hidden;
      gap: 40px;

      .policy-top {
        display: none;
      }

      @media (max-width:577px) {
        gap: 24px;
        flex-direction: column;

        .policy-top {
          display: flex;

          .policy-right {
            display: flex;
            width: 100%;
            flex-direction: column;
          }

          .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #121212;
          }

          .ant-tabs .ant-tabs-tab:hover {
            color: #121212;
          }

          .ant-tabs .ant-tabs-ink-bar {
            background-color: #121212;
          }

          .ant-tabs .ant-tabs-tab-btn:active {
            color: #121212;
          }

          .ant-tabs-top>.ant-tabs-nav::before {
            border-bottom: none;
          }

          .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
            box-shadow: none;
          }

          .ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before {
            box-shadow: none;
          }
        }

      }

      .policy-left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 25%;

        @media (max-width:577px) {
          display: none;
        }

        .radio-group {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .radio-item {
            display: flex;
            height: 240px;
            padding: var(--Spacing-space-m, 16px);
            flex-direction: column;
            align-items: center;
            gap: var(--Spacing-space-xxl, 40px);
            align-self: stretch;
            border-radius: var(--Radius-radius-xsss, 2px);
            background: #f7f7f7;
            border: 2px solid transparent;
            cursor: pointer;
            transition: all 0.3s ease;

            &.selected {
              border-radius: var(--Radius-radius-xsss, 2px);
              border: 1px solid var(--Outline-Default-outline-click, #121212);
              background: #f7f7f7;
            }

            input[type="radio"] {
              display: none;
              /* Ẩn nút radio mặc định */
            }

            /* Icon và text */
            &::before {
              content: "";
              display: block;
              width: 24px;
              height: 24px;
              margin-bottom: 8px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }

            /* Văn bản */
            span {
              font-size: 1rem;
              color: #333;
            }

            /* Hiệu ứng khi chọn */
            input[type="radio"]:checked+& {
              border-color: #007bff;
              background-color: #e6f0ff;
              box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
            }
          }

          .group-radio-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
          }
        }
      }

      .policy-right {
        width: 75%;

        @media (max-width:577px) {
          display: none;
        }

        h3 {
          color: var(--Color-Text-high-emphasis, #121212);
          text-align: justify;

          font-family: Manrope;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          letter-spacing: 0.12px;
        }

        p {
          color: var(--Color-Text-medium-emphsis, #525257);
          text-align: justify;

          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.28px;
          margin-bottom: 16px;
        }

        p strong {
          color: var(--Color-Text-high-emphasis, #121212);
          text-align: justify;

          font-family: Manrope;
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 36px;
          letter-spacing: 0.12px;
        }

        /* Attachments */
        .attachments {
          display: flex;
          justify-content: center;
          align-items: center;

          .attachment-image {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}