@media (min-width:320px) {

    /* smartphones, iPhone, portrait 480x320 phones */
    footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background: var(--Color-Base-base-virtual, #F7F7F7);
        backdrop-filter: blur(2px);

        .footer:first-child {
            display: flex;
            padding: var(--Spacing-space-lg, 24px) var(--Spacing-space-m, 16px) var(--Spacing-space-m, 16px) var(--Spacing-space-m, 16px);
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
            align-self: stretch;

            .footer--info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);
                align-self: stretch;

                .footer--info-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    /* 133.333% */
                    letter-spacing: 0.24px;
                }

                .footer--info--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--info--content-value {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-xs, 6px);
                        align-self: stretch;
                        color: var(--Color-Text-high-emphasis, #121212);

                        span:nth-child(2) {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.14px;
                        }

                        span:nth-child(3) {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0.24px;
                        }
                    }
                }
            }

            .footer--orther {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--orther-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--orther--content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--orther--content-name {
                        color: var(--Color-Text-high-emphasis, #121212);
                        text-align: center;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.14px;
                    }
                }
            }

            .footer--social {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--social-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--social--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;

                    .footer--social--content--container {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .footer--social--content--container-name {
                            color: var(--Color-Text-high-emphasis, #121212);
                            text-align: center;

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            letter-spacing: 0.28px;
                        }
                    }
                }
            }
        }

        .footer:nth-child(2) {
            display: flex;
            padding: var(--Spacing-space-m, 16px);
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 40px;
            align-self: stretch;

            .footer--left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: var(--Spacing-space-lg, 24px);
                align-self: stretch;

                .footer--left-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 60px;
                }
            }

            .footer--right {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;

                .footer--right-title {
                    color: var(--Color-Text-high-emphasis, #121212);

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    letter-spacing: 0.16px;
                }

                .footer--right-content {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;
                }
            }
        }

        .copyright {
            display: flex;
            padding: var(--Spacing-space-s, 8px) var(--Spacing-space-m, 16px) var(--Spacing-spacing-m, 16px) var(--Spacing-space-m, 16px);
            flex-direction: column;
            align-items: flex-start;
            gap: var(--Spacing-space-s, 8px);
            align-self: stretch;

            .copyright--content {
                color: var(--Color-Text-medium-emphsis, #525257);
                text-align: center;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }

            .copyright--bank {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
            }
        }
    }
}

@media (min-width:576px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {

    /* big landscape tablets, laptops, and desktops */
    footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background: var(--Color-Base-base-virtual, #F7F7F7);
        backdrop-filter: blur(2px);

        .footer:first-child {
            display: flex;
            padding: var(--Spacing-space-xxl, 40px) 135px;
            justify-content: space-between;
            flex-direction: row;
            align-items: flex-start;
            align-self: stretch;

            .footer--info {
                display: flex;
                width: 455px;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--info-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--info--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--info--content-value {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-xs, 6px);
                        align-self: stretch;
                        color: var(--Color-Text-high-emphasis, #121212);

                        span:nth-child(2) {

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                        }

                        span:nth-child(3) {
                            color: var(--Color-Text-medium-emphsis, #525257);

                            /* Body Text/Small */

                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            /* 150% */
                            letter-spacing: 0.24px;
                        }
                    }
                }
            }

            .footer--orther {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--orther-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--orther--content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--orther--content-name {
                        cursor: pointer;
                        color: var(--Color-Text-high-emphasis, #121212);
                        text-align: center;

                        /* Label/Medium */

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        /* 142.857% */
                        letter-spacing: 0.14px;
                    }
                }
            }

            .footer--social {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--social-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--social--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);

                    .footer--social--content--container {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .footer--social--content--container-name {
                            color: var(--Color-Text-high-emphasis, #121212);
                            text-align: center;

                            /* Body Text/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.28px;
                        }
                    }
                }
            }
        }

        .footer:nth-child(2) {
            display: flex;
            padding: var(--Spacing-space-m, 16px) 135px var(--Spacing-space-s, 8px) 135px;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            align-self: stretch;

            .footer--left {
                display: flex;
                width: 660px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: var(--Spacing-space-lg, 24px);

                .footer--left-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Headline/Display */

                    font-size: 72px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 96px;
                    /* 133.333% */
                }
            }

            .footer--right {
                display: flex;
                width: 400px;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;

                .footer--right-title {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 0.16px;
                }

                .footer--right-content {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;
                }
            }
        }

        .copyright {
            display: flex;
            padding: var(--Spacing-space-s, 8px) 135px var(--Spacing-spacing-m, 16px) 135px;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            align-self: stretch;

            .copyright--content {
                color: var(--Color-Text-medium-emphsis, #525257);
                text-align: center;
                font-family: Montserrat;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 200% */
            }

            .copyright--bank {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
            }
        }
    }
}

@media (min-width:1281px) {

    /* hi-res laptops and desktops */
    footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background: var(--Color-Base-base-virtual, #F7F7F7);
        backdrop-filter: blur(2px);

        .footer:first-child {
            display: flex;
            padding: var(--Spacing-web-margin-L, 60px) var(--Spacing-space-xxl, 40px) var(--Spacing-space-xxl, 40px) var(--Spacing-space-xxl, 40px);
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;

            .footer--info {
                display: flex;
                width: 455px;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--info-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--info--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--info--content-value {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-xs, 6px);
                        align-self: stretch;
                        color: var(--Color-Text-high-emphasis, #121212);

                        span:nth-child(2) {

                            /* Label/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.14px;
                        }

                        span:nth-child(3) {
                            color: var(--Color-Text-medium-emphsis, #525257);

                            /* Body Text/Small */

                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 18px;
                            /* 150% */
                            letter-spacing: 0.24px;
                        }
                    }
                }
            }

            .footer--orther {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--orther-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--orther--content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;

                    .footer--orther--content-name {
                        cursor: pointer;
                        color: var(--Color-Text-high-emphasis, #121212);
                        text-align: center;

                        /* Label/Medium */

                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        /* 142.857% */
                        letter-spacing: 0.14px;
                    }
                }
            }

            .footer--social {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-m, 16px);

                .footer--social-title {
                    color: var(--Color-Text-medium-emphsis, #525257);
                    text-align: center;

                    /* Body Text/Small */

                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px;
                    /* 150% */
                    letter-spacing: 0.24px;

                    text-transform: uppercase;
                }

                .footer--social--content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-space-s, 8px);

                    .footer--social--content--container {
                        display: flex;
                        align-items: center;
                        gap: var(--Spacing-space-s, 8px);

                        .footer--social--content--container-name {
                            color: var(--Color-Text-high-emphasis, #121212);
                            text-align: center;

                            /* Body Text/Medium */

                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.28px;
                        }
                    }
                }
            }
        }

        .footer:nth-child(2) {
            display: flex;
            padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .footer--left {
                display: flex;
                width: 660px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: var(--Spacing-space-lg, 24px);

                .footer--left-name {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Headline/Display */

                    font-size: 72px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 96px;
                    /* 133.333% */
                }
            }

            .footer--right {
                display: flex;
                width: 400px;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-space-s, 8px);
                align-self: stretch;

                .footer--right-title {
                    color: var(--Color-Text-high-emphasis, #121212);

                    /* Label/Large */

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    /* 150% */
                    letter-spacing: 0.16px;
                }

                .footer--right-content {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: var(--Spacing-space-s, 8px);
                    align-self: stretch;
                }
            }
        }

        .copyright {
            display: flex;
            padding: var(--Spacing-space-s, 8px) var(--Spacing-space-xxl, 40px) var(--Spacing-spacing-m, 16px) var(--Spacing-space-xxl, 40px);
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            .copyright--content {
                color: var(--Color-Text-medium-emphsis, #525257);
                text-align: center;
                font-family: Montserrat;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 200% */
            }

            .copyright--bank {
                display: flex;
                align-items: center;
                gap: var(--Spacing-space-s, 8px);
            }
        }
    }
}