.account-info {
}

.account-info__title {
  color: var(--Color-Text-high-emphasis, #121212);

  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.12px;
}

.account-info__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.account-info__form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.account-info__form-group label {
  color: #000;

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.account-info__form-group input,
.account-info__form-group select {
  display: flex;
  padding: 10px 0px;
  align-items: center;
  gap: var(--Spacing-space-s, 8px);
  align-self: stretch;

  color: var(--Color-Text-medium-emphsis, #525257);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
}

.account-info__form-group input::placeholder {
  color: #bbb;
}

.account-info__form-group--date .account-info__date-inputs {
  display: flex;
  gap: 8px;
}

.account-info__form-group--date input {
  width: calc(33.33% - 5px);
}

.account-info__form-group--checkbox {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.account-info__form-group--checkbox label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  line-height: 1.5;

  input{
    margin-right: 8px;
  }

  a{
    margin-left: 4px;
  }
}

.account-info__form-group--checkbox a {
  color: #121212;
  text-decoration: none;
}

.account-info__form-group--checkbox a:hover {
  text-decoration: underline;
}

.account-info__form-actions {
  display: flex;
  gap: 16px;
}

.account-info__form-actions .btn {
    display: flex;
    width: 30%;
    padding: 10px var(--Spacing-space-lg, 24px);
    justify-content: center;
    align-items: center;
    gap: 10px;
    
  cursor: pointer;
}

.account-info__form-actions .btn.btn-secondary {
    border-radius: var(--Radius-radius-xsss, 2px);
    border: 1px solid var(--Outline-Default-outline-hover, #121212);
}

.account-info__form-actions .btn.btn-secondary:hover {
    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Base-base-black, #1D1D1F);
}

.account-info__form-actions .btn.btn-primary {
    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Scheme-primary, #F1BE87);
}

.account-info__form-actions .btn.btn-primary:hover {
    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Scheme-primary-hover, #AB8760);
}
