@media (min-width:320px) {
    .tag--primium {
        display: inline-flex;
        padding: var(--Spacing-space-xss, 4px);
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 1px;
        background: var(--Color-Base-base-black, #1D1D1F);
        z-index: 10;

        span {
            color: var(--Color-Base-base-white, #FFF);
            leading-trim: both;
            text-edge: cap;
            font-size: 8px;
            font-style: normal;
            font-weight: 600;
            line-height: 8px;
            letter-spacing: 0.08px;
        }

        &.absolute--top-left {
            position: absolute;
            left: 4px;
            top: 4px;
        }
    }

    .tag--price {
        display: flex;
        padding: var(--Spacing-space-xss, 4px);
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 1px;
        background: #FCE7E8;
        box-sizing: border-box;
        z-index: 10;

        span {
            color: #8F223A;
            leading-trim: both;
            text-edge: cap;
            font-size: 8px;
            font-style: normal;
            font-weight: 600;
            line-height: 8px;
            letter-spacing: 0.08px;
        }

        &.absolute--top-left {
            position: absolute;
            left: 4px;
            top: 4px;
        }
    }

    .tag {
        display: flex;
        padding: var(--Spacing-space-xsss, 2px) var(--Spacing-space-xss, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        border-radius: 1px;
        background: var(--Color-Scheme-secondary-container, #F5F6F6);

        span {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            /* 140% */
            letter-spacing: 0.2px;
        }
    }
}

@media (min-width:576px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {
    .tag--primium {
        display: flex;
        padding: var(--Spacing-space-xss, 4px) var(--Spacing-space-s, 8px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xsss, 2px);
        border-radius: 1px;
        background: var(--Color-Base-base-black, #1D1D1F);
        z-index: 10;

        span {
            color: var(--Color-Base-base-white, #FFF);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.12px;
        }

        &.absolute--top-left {
            position: absolute;
            left: 4px;
            top: 4px;
        }
    }

    .tag--price {
        display: flex;
        padding: var(--Spacing-space-xss, 4px) var(--Spacing-space-s, 8px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xsss, 2px);
        border-radius: 1px;
        background: #FCE7E8;
        box-sizing: border-box;
        z-index: 10;

        span {
            color: #8F223A;

            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 0.12px;
        }

        &.absolute--top-left {
            position: absolute;
            left: 8px;
            top: 8px;
        }
    }

    .tag {
        display: flex;
        padding: var(--Spacing-space-xsss, 2px) var(--Spacing-space-xss, 4px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        border-radius: 1px;
        background: var(--Color-Scheme-secondary-container, #F5F6F6);

        span {
            color: var(--Color-Text-medium-emphsis, #525257);

            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            /* 140% */
            letter-spacing: 0.2px;
        }
    }
}

@media (min-width:1441px) {}