@import "./../../../src/variable.scss";

.modal-container {
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;

  z-index: -1;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease-in-out;

  .modal-right {
    position: fixed;
    right: 0;
    top: 0;

    z-index: 2100;

    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .modal-right-in {
    transform: translateX(0%);
  }

  .modal-right-out {
    transform: translateX(100%);
  }

  .modal-fadeup {
    position: fixed;
    right: 0;
    top: 0;

    z-index: 2100;
    opacity: 1;

    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out;
  }

  .modal-fadeup-leave {
    opacity: 0;
  }
}

.modal-container-show {
  z-index: 2000;
  opacity: 1;
}

.product-detail {
  position: relative;
  margin-top: 128px;

  .product-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: $Spacing-space-xl;

    background-color: $Color-Base-base-virtual;
    padding: 60px 40px;
    min-height: 90vh;

    &__image {
      position: relative;
      width: 66%;
      height: fit-content;

      &__button {
        position: absolute;
        bottom: 30px;
        left: 0;
        transform: translateY(50%);

        background-color: $Color-Base-base-virtual;
        z-index: 100;

        button {
          color: $high-emphasis;
        }
      }

      &__number {
        position: absolute;
        bottom: 30px;
        left: 480px;
        transform: translateY(50%);

        font-size: 14px;
        color: $medium-emphasis;
        padding: 2px 12px;
        background-color: $Color-Base-base-virtual;
        z-index: 100;
      }
    }

    &__content {
      width: 33%;
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-lg;

      &__type {
        color: $high-emphasis;
        font-size: $Font-size-xs;
        font-weight: 600;
        margin-bottom: 0;
      }

      &__name {
        color: $high-emphasis;
        font-size: 36px;
        font-weight: 800;
        margin-bottom: 0;
      }

      &__desc {
        color: $medium-emphasis;
        font-size: $Font-size-xs;
        font-weight: 500;
        margin-bottom: 0;
      }

      &__custom {
        &__header {
          color: $high-emphasis;
          font-size: $Font-size-xs;
          font-weight: 700;
          margin-bottom: 0;
        }

        &__body {
          display: flex;
          flex-direction: column;
          gap: $Spacing-space-sm;

          padding: $Spacing-space-m;
          border-radius: 2px;
          border: 1px solid rgba(18, 18, 18, 0.16);
          background-color: transparent;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            border: 1px solid $high-emphasis;
            background-color: #fff;
          }

          .custom-body__title {
            color: $medium-emphasis;
            font-size: $Font-size-xss;
            font-weight: 500;
            margin-bottom: 0;
          }

          .custom-body__number {
            color: $high-emphasis;
            font-size: $Font-size-xss;
            font-weight: 600;
            margin-bottom: 0;
          }

          .custom-body__image {
            width: 96px;
            height: 40px;
            object-fit: contain;
            margin-right: 8px;
          }

          .custom-body__name {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0;
          }

          .custom-body__color {
            color: $medium-emphasis;
            font-size: $Font-size-xss;
            font-weight: 600;
            margin-bottom: 0;
          }
        }
      }

      &__price {
        display: flex;
        flex-direction: column;
        gap: $Spacing-space-s;

        .price-original {
          color: $medium-emphasis;
          font-size: 14px;
          font-weight: 500;
          text-decoration: line-through;
        }

        .price-sale {
          color: $high-emphasis;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

    .swiper {
      height: max-content;
      padding-bottom: 60px;

      .swiper-slide {
        max-height: 70vh;
        min-height: 60vh;
        background-color: #f7f7f7;
      }

      // Pagination
      .swiper-pagination {
        left: 0;
        bottom: 30px;
        transform: translate(0, 50%);
        padding: 0 50px 0 0;
        z-index: 10;
      }

      .swiper-pagination-bullet {
        width: 48px;
        height: 6px;
        background: transparent;
        border-radius: 0;
        margin: 4px;
        opacity: 1;
      }

      .swiper-pagination-bullet-active-prev,
      .swiper-pagination-bullet-active-next {
        transform: scale(0.6, 0.4);
        border-radius: 0;
        background: #cdd1d4;
      }

      .swiper-pagination-bullet-active-main {
        transform: scale(1, 0.4);
        border-radius: 0;
        background: #808b90;
      }

      .swiper-pagination-bullet-active-prev-prev,
      .swiper-pagination-bullet-active-next-next {
        transform: scale(0);
        border-radius: 0;
        background: transparent;
      }

      .swiper-pagination-bullet-active {
        transform: scale(1);
        background: $high-emphasis;
        border-radius: 2px;
      }
      // Pagination

      // Prev, Next
      .swiper-button-prev,
      .swiper-button-next {
        &::after {
          color: $high-emphasis;
          font-size: 14px;
          font-weight: bolder;
        }
      }

      .swiper-button-prev {
        position: absolute;
        left: calc(100% - 70px);
        top: calc(100% - 30px);
      }

      .swiper-button-next {
        position: absolute;
        right: 0;
        top: calc(100% - 30px);
      }
      // Prev, Next
    }
  }

  .promo {
    padding: 60px 40px 32px 40px;

    .promo-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;

      max-width: 1520px;
      margin: 0 auto;

      &__card {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: $Spacing-space-m;

        text-align: center;

        &__title {
          color: $high-emphasis;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0;
        }

        &__content {
          color: $medium-emphasis;
          font-size: $Font-size-xs;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
    }
  }

  .key-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    padding: 60px 40px;

    &__image {
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      width: 50%;
      background-color: $Color-Base-base-virtual;
      padding: $Spacing-space-xxl;

      font-size: 14px;
      font-weight: 500;
    }
  }

  .blog {
    display: flex;
    flex-direction: column;
    gap: $Spacing-space-xxl;

    .ant-tabs {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
        }
      }

      .ant-tabs-tab {
        font-size: 16px;
        font-weight: 600;
        padding: 8px !important;

        &:hover {
          color: $high-emphasis;
        }
      }

      .ant-tabs-nav {
        padding: 40px 40px;
        margin-bottom: 0;
        background-color: #f7f7f7;

        &::before {
          display: none;
        }
      }

      .ant-tabs-tab-active > .ant-tabs-tab-btn {
        color: $high-emphasis !important;
      }

      .ant-tabs-ink-bar {
        background: $high-emphasis !important;
      }

      .ant-tabs-content {
        color: $high-emphasis;
        font-size: $Font-size-xs;
        font-weight: 500;
      }
    }

    &__tab-content {
      margin: 0 auto;
      max-width: 1360px;
      padding: 40px 40px;
      overflow: hidden;

      img {
        height: auto;
        object-fit: contain;
        padding: 8px 0;
        width: 90%;
        padding-left: 10%;
        pointer-events: none;
      }
    }

    .btn-readMore {
      display: none;
    }
  }
}

@media all and (min-width: 961px) and (max-width: 1440px) {
}

@media all and (min-width: 577px) and (max-width: 960px) {
  .modal-container {
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;

    z-index: -1;

    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease-in-out;

    .modal-right {
      position: fixed;
      right: 0;
      top: 0;

      z-index: 2100;

      width: 100%;
      height: 100%;
      background: transparent;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }

    .modal-right-in {
      transform: translateX(0%);
    }

    .modal-right-out {
      transform: translateX(100%);
    }
  }

  .modal-container-show {
    z-index: 2000;
    opacity: 1;
  }

  .product-detail {
    position: relative;
    margin-top: 56px;

    .product-intro {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: start;
      gap: $Spacing-space-xl;

      background-color: $Color-Base-base-virtual;
      padding: 40px 16px;

      &__image {
        position: relative;
        width: 100%;

        &__button {
          position: absolute;
          bottom: 30px;
          left: 0;
          transform: translateY(50%);

          background-color: $Color-Base-base-virtual;
          z-index: 100;
        }

        &__number {
          position: absolute;
          bottom: 30px;
          left: unset;
          right: 0;
          transform: translateY(50%);

          font-size: 14px;
          color: $medium-emphasis;
          padding: 2px 12px;
          background-color: $Color-Base-base-virtual;
          z-index: 100;
        }
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $Spacing-space-lg;

        &__type {
          color: $high-emphasis;
          font-size: $Font-size-xs;
          font-weight: 600;
          margin-bottom: 0;
        }

        &__name {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }

        &__desc {
          color: $medium-emphasis;
          font-size: $Font-size-xs;
          font-weight: 500;
          margin-bottom: 0;
        }

        &__custom {
          &__header {
            color: $high-emphasis;
            font-size: $Font-size-xs;
            font-weight: 700;
            margin-bottom: 0;
          }

          &__body {
            display: flex;
            flex-direction: column;
            gap: $Spacing-space-sm;

            padding: $Spacing-space-m;
            border-radius: 2px;
            border: 1px solid #aeaeb7;
            cursor: pointer;

            .custom-body__title {
              color: $medium-emphasis;
              font-size: $Font-size-xss;
              font-weight: 500;
              margin-bottom: 0;
            }

            .custom-body__number {
              color: $high-emphasis;
              font-size: $Font-size-xss;
              font-weight: 600;
              margin-bottom: 0;
            }

            .custom-body__image {
              width: 96px;
              height: 36px;
              object-fit: contain;
              margin-right: 8px;
            }

            .custom-body__name {
              color: $high-emphasis;
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0;
            }

            .custom-body__color {
              color: $medium-emphasis;
              font-size: $Font-size-xss;
              font-weight: 600;
              margin-bottom: 0;
            }
          }
        }

        &__price {
          display: flex;
          flex-direction: column;
          gap: $Spacing-space-s;

          .price-original {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
            text-decoration: line-through;
          }

          .price-sale {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .swiper {
        padding-bottom: 60px;

        .swiper-slide {
          max-height: 70vh;
          min-height: unset;
          background-color: #f7f7f7;
        }

        // Pagination
        .swiper-pagination {
          left: 0;
          bottom: 30px;
          transform: translate(0, 50%);
          padding: 0 50px 0 0;
          z-index: 10;
        }

        .swiper-pagination-bullet {
          width: 24px;
          height: 4px;
          background: transparent;
          border-radius: 0;
          margin: 4px;
          opacity: 1;
        }

        .swiper-pagination-bullet-active-prev,
        .swiper-pagination-bullet-active-next {
          transform: scale(0.6, 0.4);
          border-radius: 0;
          background: #cdd1d4;
        }

        .swiper-pagination-bullet-active-main {
          transform: scale(1, 0.4);
          border-radius: 0;
          background: #808b90;
        }

        .swiper-pagination-bullet-active-prev-prev,
        .swiper-pagination-bullet-active-next-next {
          transform: scale(0);
          border-radius: 0;
          background: transparent;
        }

        .swiper-pagination-bullet-active {
          transform: scale(1);
          background: $high-emphasis;
          border-radius: 0px;
        }
        // Pagination

        // Prev, Next
        .swiper-button-prev,
        .swiper-button-next {
          &::after {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: bolder;
          }
        }

        .swiper-button-prev {
          display: none;
        }

        .swiper-button-next {
          display: none;
        }
        // Prev, Next
      }
    }

    .promo {
      padding: 40px 16px;

      .promo-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        gap: 24px;

        max-width: 1520px;
        margin: 0 auto;

        &__card {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          gap: $Spacing-space-s;

          text-align: start;

          &__title {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
          }

          &__content {
            color: $medium-emphasis;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
    }

    .key-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      padding: 24px 16px;

      &__image {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__content {
        width: 100%;
        background-color: transparent;
        padding: 16px;

        font-size: 14px;
        font-weight: 500;
      }
    }

    .blog {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xxl;

      max-height: 400px;
      overflow: hidden;
      transition: all 0.5s ease-in;

      .ant-tabs {
        height: 100%;
        .ant-tabs-content {
          height: 100%;
          .ant-tabs-tabpane {
            height: 100%;
          }
        }

        .ant-tabs-tab {
          font-size: 16px;
          font-weight: 600;
          padding: 8px !important;

          &:hover {
            color: $high-emphasis;
          }
        }

        .ant-tabs-nav {
          padding: 24px 16px;
          margin-bottom: 0;
          background-color: #f7f7f7;

          &::before {
            display: none;
          }
        }

        .ant-tabs-tab-active > .ant-tabs-tab-btn {
          color: $high-emphasis !important;
        }

        .ant-tabs-ink-bar {
          background: $high-emphasis !important;
        }

        .ant-tabs-content {
          color: $high-emphasis;
          font-size: $Font-size-xs;
          font-weight: 500;
        }
      }

      &__tab-content {
        padding: 40px 16px;

        img {
          height: auto;
          object-fit: contain;
          padding: 8px 0;
          width: 100%;
          padding-left: 0;
        }
      }

      .btn-readMore {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        font-size: 14px;
        font-weight: 600;
        color: $high-emphasis;
        padding: $Spacing-space-lg 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 41.32%,
          #fff 92.86%
        );
      }
    }

    .blog-full {
      max-height: 1000vh;
    }
  }
}

@media all and (max-width: 576px) {
  .modal-container {
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;

    z-index: -1;

    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.3s ease-in-out;

    .modal-right {
      position: fixed;
      right: 0;
      top: 0;

      z-index: 2100;

      width: 100%;
      height: 100%;
      background: transparent;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }

    .modal-right-in {
      transform: translateX(0%);
    }

    .modal-right-out {
      transform: translateX(100%);
    }
  }

  .modal-container-show {
    z-index: 2000;
    opacity: 1;
  }

  .product-detail {
    position: relative;
    margin-top: 56px;

    .product-intro {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: start;
      gap: $Spacing-space-xl;

      background-color: $Color-Base-base-virtual;
      padding: 40px 16px;

      &__image {
        position: relative;
        width: 100%;

        &__button {
          position: absolute;
          bottom: 30px;
          left: 0;
          transform: translateY(50%);

          background-color: $Color-Base-base-virtual;
          z-index: 100;
        }

        &__number {
          position: absolute;
          bottom: 30px;
          left: unset;
          right: 0;
          transform: translateY(50%);

          font-size: 14px;
          color: $medium-emphasis;
          padding: 2px 12px;
          background-color: $Color-Base-base-virtual;
          z-index: 100;
        }
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: $Spacing-space-lg;

        &__type {
          color: $high-emphasis;
          font-size: $Font-size-xs;
          font-weight: 600;
          margin-bottom: 0;
        }

        &__name {
          color: $high-emphasis;
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 0;
        }

        &__desc {
          color: $medium-emphasis;
          font-size: $Font-size-xs;
          font-weight: 500;
          margin-bottom: 0;
        }

        &__custom {
          &__header {
            color: $high-emphasis;
            font-size: $Font-size-xs;
            font-weight: 700;
            margin-bottom: 0;
          }

          &__body {
            display: flex;
            flex-direction: column;
            gap: $Spacing-space-sm;

            padding: $Spacing-space-m;
            border-radius: 2px;
            border: 1px solid #aeaeb7;
            cursor: pointer;

            .custom-body__title {
              color: $medium-emphasis;
              font-size: $Font-size-xss;
              font-weight: 500;
              margin-bottom: 0;
            }

            .custom-body__number {
              color: $high-emphasis;
              font-size: $Font-size-xss;
              font-weight: 600;
              margin-bottom: 0;
            }

            .custom-body__image {
              width: 96px;
              height: 36px;
              object-fit: contain;
              margin-right: 8px;
            }

            .custom-body__name {
              color: $high-emphasis;
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 0;
            }

            .custom-body__color {
              color: $medium-emphasis;
              font-size: $Font-size-xss;
              font-weight: 600;
              margin-bottom: 0;
            }
          }
        }

        &__price {
          display: flex;
          flex-direction: column;
          gap: $Spacing-space-s;

          .price-original {
            color: $medium-emphasis;
            font-size: 14px;
            font-weight: 500;
            text-decoration: line-through;
          }

          .price-sale {
            color: $high-emphasis;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .swiper {
        height: 275px;
        padding-bottom: 60px;

        .swiper-slide {
          max-height: unset;
          min-height: unset;
          background-color: #f7f7f7;
        }

        // Pagination
        .swiper-pagination {
          left: 0;
          bottom: 30px;
          transform: translate(0, 50%);
          padding: 0 50px 0 0;
          z-index: 10;
        }

        .swiper-pagination-bullet {
          width: 24px;
          height: 4px;
          background: transparent;
          border-radius: 0;
          margin: 4px;
          opacity: 1;
        }

        .swiper-pagination-bullet-active-prev,
        .swiper-pagination-bullet-active-next {
          transform: scale(0.6, 0.4);
          border-radius: 0;
          background: #cdd1d4;
        }

        .swiper-pagination-bullet-active-main {
          transform: scale(1, 0.4);
          border-radius: 0;
          background: #808b90;
        }

        .swiper-pagination-bullet-active-prev-prev,
        .swiper-pagination-bullet-active-next-next {
          transform: scale(0);
          border-radius: 0;
          background: transparent;
        }

        .swiper-pagination-bullet-active {
          transform: scale(1);
          background: $high-emphasis;
          border-radius: 0px;
        }
        // Pagination

        // Prev, Next
        .swiper-button-prev,
        .swiper-button-next {
          &::after {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: bolder;
          }
        }

        .swiper-button-prev {
          display: none;
        }

        .swiper-button-next {
          display: none;
        }
        // Prev, Next
      }
    }

    .promo {
      padding: 40px 16px;

      .promo-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        gap: 24px;

        max-width: 1520px;
        margin: 0 auto;

        &__card {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          gap: $Spacing-space-s;

          text-align: start;

          &__title {
            color: $high-emphasis;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0;
          }

          &__content {
            color: $medium-emphasis;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
          }
        }
      }
    }

    .key-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;

      padding: 24px 16px;

      &__image {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__content {
        width: 100%;
        background-color: transparent;
        padding: 16px;

        font-size: 14px;
        font-weight: 500;
      }
    }

    .blog {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: $Spacing-space-xxl;

      max-height: 400px;
      overflow: hidden;
      transition: all 0.5s ease-in;

      .ant-tabs {
        height: 100%;
        .ant-tabs-content {
          height: 100%;
          .ant-tabs-tabpane {
            height: 100%;
          }
        }

        .ant-tabs-tab {
          font-size: 16px;
          font-weight: 600;
          padding: 8px !important;

          &:hover {
            color: $high-emphasis;
          }
        }

        .ant-tabs-nav {
          padding: 24px 16px;
          margin-bottom: 0;
          background-color: #f7f7f7;

          &::before {
            display: none;
          }
        }

        .ant-tabs-tab-active > .ant-tabs-tab-btn {
          color: $high-emphasis !important;
        }

        .ant-tabs-ink-bar {
          background: $high-emphasis !important;
        }

        .ant-tabs-content {
          color: $high-emphasis;
          font-size: $Font-size-xs;
          font-weight: 500;
        }
      }

      &__tab-content {
        padding: 40px 16px;

        img {
          height: auto;
          object-fit: contain;
          padding: 8px 0;
          width: 100%;
          padding-left: 0;
        }
      }

      .btn-readMore {
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        font-size: 14px;
        font-weight: 600;
        color: $high-emphasis;
        padding: $Spacing-space-lg 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.8) 41.32%,
          #fff 92.86%
        );
      }
    }

    .blog-full {
      max-height: 1000vh;
    }
  }
}
