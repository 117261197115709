.login-page {
  padding: 60px 40px;
  margin-top: 128px;
  background-color: #f7f7f7;
}

.login-page__title {
  text-align: start;
  color: var(--Color-Text-high-emphasis, #121212);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
}

.btn-back {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
  padding: 0px !important;
}

.login-page__content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.login-page__form {
  width: 70%;
  background: #fff;
  padding: var(--Spacing-space-xl, 32px);
}
.login-page__register {
  width: 30%;
  background: #fff;
  padding: var(--Spacing-space-xl, 32px);
  height: fit-content;
}

.login-page__form h2,
.login-page__register h2 {
  color: var(--Color-Text-high-emphasis, #121212);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  letter-spacing: 0.12px;

  margin-bottom: 16px;
}

.login-page__form p,
.login-page__register p {
  color: var(--Color-Text-medium-emphsis, #525257);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;

  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 40px;

  .error {
    color: var(--Color-Scheme-error, #e05364);
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1px;
  }
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  // margin-bottom: 40px;
  color: var(--Color-Text-medium-emphsis, #525257);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.28px;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.9rem;

  label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  a {
    color: #121212;
  }
}
.form-options-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0.9rem;

  input {
    margin-right: 8px;
  }

  label {
    display: flex;
    align-items: center;
  }

  a {
    color: var(--Color-Text-high-emphasis, #121212);

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    margin-left: 4px;
    margin-right: 4px;
  }
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  display: flex;
  width: 40%;
  color: #121212;
  padding: 10px var(--Spacing-space-lg, 24px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--Radius-radius-xsss, 2px);
  background: var(--Color-Scheme-primary, #f1be87);
}

.btn-primary:hover {
  background-color: #ab8760;
  color: #121212;
}

.btn-secondary {
  display: flex;
  padding: 10px var(--Spacing-space-lg, 24px);
  justify-content: center;
  align-items: center;
  background-color: transparent;
  gap: 10px;
  color: #121212;
  border-radius: var(--Radius-radius-xsss, 2px);
  border: 1px solid var(--Outline-Default-outline-hover, #121212);
}

.btn-secondary:hover {
  border-radius: var(--Radius-radius-xsss, 2px);
  background: var(--Color-Base-base-black, #1d1d1f);
}

.login-page__terms {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #666;
}

.login-page__terms a {
  color: #121212;
  text-decoration: none;
}

.login-page__terms a:hover {
  text-decoration: underline;
}

.login-page__register ul {
  list-style: disc;
  margin-bottom: 20px;
}

.login-page__register ul li {
  margin-bottom: 10px;
  font-size: 0.9rem;
  display: flex;
  gap: 8px;
  color: var(--Color-Text-high-emphasis, #121212);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.14px;
}

.login-page__form-subtitle {
  display: none;
}

.login-page__register-benefits {
  list-style: none;
  padding: 0;
  margin: 0;
}
.login-page__register-benefit {
  list-style: none;
  padding: 0;
  margin: 0;
}

.login-page__register-benefit {
  position: relative;
}

.group-navigate-register {
  display: none;
}

@media (max-width: 768px) {
  .login-page {
    padding: 40px 16px;
  }
  .login-page__register {
    display: none;
  }

  .login-page__title {
    color: var(--Color-Text-high-emphasis, #121212);

    font-family: Manrope;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 42px;
    margin-bottom: 24px;
  }

  .login-page__form {
    width: 100%;
  }
  .login-page__form-title {
    display: none;
  }
  .login-page__form-subtitle {
    display: flex;
    margin-bottom: 24px !important;
  }
  .login-page__form p,
  .login-page__register p {
    color: var(--Color-Text-high-emphasis, #121212);

    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.2px;
  }

  .login-page__terms {
    color: var(--Color-Text-medium-emphsis, #525257) !important;

    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.24px !important;
  }
  .login-page__form-description {
    color: var(--Color-Text-medium-emphsis, #525257) !important;

    font-family: Manrope;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.24px;
    margin-bottom: 24px !important; 
  }

  .form-options-register{
    display: none;
  }
  .btn-primary {
    width: 100%;
  }

  .error-message {
    color: var(--Color-Scheme-error, #e05364) !important;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
  }

  .group-navigate-register {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    border-top: 1px solid #1d1d1f29;
    padding-top: 26px;

    p {
      color: var(--Color-Text-medium-emphsis, #525257);
      text-align: center;

      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.28px;
      margin-bottom: 0;
    }
    button {
      color: var(--Color-Text-high-emphasis, #121212);
      text-align: center;

      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }
}
