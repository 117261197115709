.btn--dropdown {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    padding: var(--Spacing-space-s, 8px) 16px;
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-space-s, 8px);

    border-radius: var(--Radius-radius-xsss, 2px);
    border: 1px solid var(--Outline-Default-outline-default, #121212);

    .dropdown--container {
        position: absolute;
        transition: all .8s ease-in-out;

        &.hidden {
            display: none;
        }

        &.show {
            display: flex;
            top: calc(100% + 5px);
            left: 0;
            z-index: 20;
        }

        &.shrink {
            transform: translateX(-100%);
            z-index: -1;

        }

        &.full--screen {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
        }
    }


    &:hover {
        border-radius: var(--Radius-radius-xsss, 2px);
        border: 1px solid var(--Outline-Default-outline-click, #121212);
        background: var(--Color-Base-base-black, #1D1D1F);

        .btn--dropdown--content {
            color: var(--Color-Text-high-wihte, #FFF);

            .btn--dropdown--content-title {
                text-align: center;

                /* Label/Medium */

                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                /* 142.857% */
                letter-spacing: 0.14px;
            }
        }

        .btn--dropdown--badge {
            border-radius: var(--Radius-radius-rounded, 999px);
            background: var(--Color-Text-high-wihte, #FFF);

            .btn--dropdown--badge-content {
                color: var(--Color-Text-high-emphasis, #121212);
                leading-trim: both;
                text-edge: cap;

                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 12px;
                /* 100% */
                letter-spacing: 0.12px;
            }
        }
    }

    .btn--dropdown--content {
        display: flex;
        align-items: center;
        gap: var(--Spacing-space-xss, 4px);
        color: var(--Color-Text-medium-emphsis, #525257);

        .btn--dropdown--content-title {
            text-align: center;

            /* Label/Medium */

            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.14px;
        }

        .btn--dropdown--content-icon {
            display: flex;
            width: 16px;
            height: 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .btn--dropdown--badge {
        display: flex;
        width: 16px;
        height: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-s, 8px);
        border-radius: var(--Radius-radius-rounded, 999px);
        background: var(--Color-Base-base-black, #1D1D1F);

        .btn--dropdown--badge-content {
            color: var(--Color-Text-high-wihte, #FFF);
            leading-trim: both;
            text-edge: cap;

            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            /* 100% */
            letter-spacing: 0.12px;
        }
    }

    &.small {
        .btn--dropdown--content-title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.12px;
        }
    }

    &:hover.small{
        .btn--dropdown--content-title {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.12px;
        }
    }

}