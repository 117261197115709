$Color-Scheme-primary: #f1be87;
$secondary-color: #2ecc71;
$grey-color: #f5f5f3;
$high-emphasis: #121212;
$medium-emphasis: #525257;
$low-emphasis: #b3b3b7;
$high-white: #ffffff;
$Color-Base-base-virtual: #f7f7f7;

$Spacing-space-xs: 6px;
$Spacing-space-s: 8px;
$Spacing-space-sm: 12px;
$Spacing-space-m: 16px;
$Spacing-space-lg: 24px;
$Spacing-space-xl: 32px;
$Spacing-space-xxl: 40px;

$Radius-radius-xsss: 2px;

$Font-size-xss: 12px;
$Font-size-xs: 14px;
$Font-size-s: 16px;
$Font-size-m: 24px;
$Font-size-lg: 32px;
$Font-size-xl: 48px;
$Font-size-xxl: 54px;
