button {
    border: none;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
}

.btn--icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--Color-Text-high-emphasis, #121212);
    transition: all .1s ease-in-out;
    border-radius: 100px;


    &:hover,
    &:focus {
        border-radius: 100px;
        background: rgba(29, 29, 31, 0.12);
        color: white;

    }

    &:disabled {
        color: #BBBBBB;

    }

    &.small {
        width: 24px;
        height: 24px;
        padding: var(--Spacing-space-xss, 4px);
        gap: 10px;
    }

    &.medium {
        padding: var(--Spacing-space-s, 8px);
        gap: 10px;
    }

    &.large {
        height: 44px;
        width: 44px;
        padding: 10px;
        gap: 10px;
    }
}

.btn--icon--solid {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--Radius-radius-rounded, 999px);
    background: rgba(29, 29, 31, 0.12);
    color: black;

    &:hover,
    &:focus {
        color: white;

    }

    &:disabled {
        color: #BBBBBB;
        background-color: white;
        pointer-events: none;
    }

    &.small {
        width: 24px;
        height: 24px;
        padding: var(--Spacing-space-xss, 4px);
        gap: 10px;
    }

    &.medium {
        padding: var(--Spacing-space-s, 8px);
        gap: 10px;
    }

    &.large {
        height: 44px;
        width: 44px;
        padding: 10px;
        gap: 10px;
    }
}

.btn--outline {
    flex: 1 0 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-radius-xsss, 2px);
    border: 1px solid var(--Outline-Default-outline-click, #121212);



    &.small {
        padding: var(--Spacing-space-s, 8px) 16px;
        gap: 10px;
        height: 36px;
        box-sizing: border-box;
        color: var(--Color-Text-high-emphasis, #121212);
        text-align: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.14px;

    }

    &.medium {
        padding: 10px var(--Spacing-space-lg, 24px);
        gap: 10px;
        box-sizing: border-box;

        color: var(--Color-Text-high-emphasis, #121212);
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;
    }

    &:hover,
    &:focus {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Base-base-black, #1D1D1F);


        color: var(--Color-Text-high-wihte, #FFF);
        text-align: center;

        /* Label/Medium */
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.14px;

    }
}

.btn--solid {
    flex: 1 0 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-radius-xsss, 2px);
    background: var(--Color-Scheme-primary, #F1BE87);



    &.small {
        padding: var(--Spacing-space-s, 8px) 16px;
        gap: 10px;


        color: var(--Color-Text-high-emphasis, #121212);
        text-align: center;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.14px;

    }

    &.medium {
        padding: 10px var(--Spacing-space-lg, 24px);
        gap: 10px;


        color: var(--Color-Text-high-emphasis, #121212);
        text-align: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;

    }

    &.pre--icon {
        padding: 10px var(--Spacing-space-m, 16px) 10px var(--Spacing-space-lg, 24px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-space-xs, 6px);
    }

    &:hover,
    &:focus {
        border-radius: var(--Radius-radius-xsss, 2px);
        background: var(--Color-Scheme-primary-hover, #AB8760);
    }

    &:disabled {
        color: #BBBBBB;
        background: #000;
    }
}

.btn--text {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--Spacing-space-s, 8px);
    color: var(--Color-Text-high-emphasis, #121212);


    .btn--text-title {
        &.hover-underline-animation {
            &:after {
                background-color: #121212;
            }
        }
    }

    &.medium {
        .btn--text-title {
            text-align: center;

            /* Label/Large */

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            /* 150% */
            letter-spacing: 0.16px;


        }
    }

    &.small {
        .btn--text-title {
            text-align: center;

            /* Label/Medium */
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: 0.14px;
        }
    }

}

.button--radio--container {
    display: flex;
    padding: var(--Spacing-space-m, 16px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-m, 16px);
    align-self: stretch;
    border-radius: var(--Radius-radius-xsss, 2px);
    border: 1px solid var(--Outline-Default-outline-default, rgba(18, 18, 18, 0.16));
    cursor: pointer;

    .button--radio--container--title {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .button--radio--container--body {
        display: flex;
        padding: var(--Spacing-space-m, 16px) var(--Spacing-space-xxl, 40px);
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;

        .button--radio--container--body--title {
            display: flex;
            min-height: 24px;
            align-items: start;
            gap: 16px;
            align-self: stretch;
        }
    }

    input {
        &[type="radio"] {
            display: flex;
            padding: 3px;
            align-items: flex-start;
            gap: 10px;
            height: 24px;
            width: 24px;
            flex: none;

            accent-color: #121212;
            cursor: pointer;

        }

    }

    label {
        span {
            display: flex;
            align-items: start;
            gap: var(--Spacing-space-s, 8px);
            flex: 1 0 0;

            color: var(--Color-Text-high-emphasis, #121212);

            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.28px;
            cursor: pointer;


            svg {
                display: flex;
                min-width: 24px;
                height: 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }

            &.sub--title {
                min-height: 10px;
                align-self: stretch;
                color: var(--Color-Text-medium-emphsis, #525257);

                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.24px;
            }
        }

    }

    &:has(input[type="radio"]:checked) {
        border-radius: var(--Radius-radius-xsss, 2px);
        border: 1px solid var(--Outline-Default-outline-hover, #121212);
    }
}

.show--error {
    .button--radio--container {
        &:has(input:invalid) {
            border: 1px solid var(--Outline-stroke-error, #F08B79);
        }
    }
}