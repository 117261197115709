@import "./../../../src/variable.scss";

.modal-custom {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  width: 34%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
    2px -8px 22px 0px rgba(66, 66, 66, 0.05),
    0px 8px 8px 0px rgba(66, 66, 66, 0.07);
  padding: $Spacing-space-lg;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $Spacing-space-lg;

    &__title {
      color: $high-emphasis;
      font-size: 36px;
      font-weight: 800;
    }

    &__close {
      width: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      padding: 8px;
      border: none;
      border-radius: 50%;
      background: transparent;
      color: $high-emphasis;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background: rgba(29, 29, 31, 0.12);
      }
    }
  }

  &__image {
    position: relative;
    display: none;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: $Spacing-space-lg;

    &__option {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      gap: $Spacing-space-lg;

      width: 100%;
      padding: $Spacing-space-m 0;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
      align-items: start;
      gap: 8px;

      width: 100%;
      height: 60vh;
      overflow: auto;

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        gap: $Spacing-space-s;

        // width: calc((100% - 32px) / 3);
        width: calc((100% - 16px) / 2);
        padding: $Spacing-space-s;
        border: 1px solid transparent;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        img {
          width: 100%;
          object-fit: cover;
        }

        p {
          color: $high-emphasis;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0;
        }

        &:hover {
          border-color: $low-emphasis;
        }
      }
    }
  }

  .modal-custom__body__content__item--active {
    border: 1px solid $high-emphasis;

    &:hover {
      border-color: $high-emphasis;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: $Spacing-space-lg;
    border-top: 1px solid rgba(29, 29, 31, 0.16);

    &__price {
      color: $high-emphasis;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

@media all and (min-width: 577px) and (max-width: 1440px) {
}

@media all and (max-width: 960px) {
  .modal-custom {
    position: absolute;
    right: 0;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 14px 30.8px 0px rgba(66, 66, 66, 0.24),
      2px -8px 22px 0px rgba(66, 66, 66, 0.05),
      0px 8px 8px 0px rgba(66, 66, 66, 0.07);
    padding: $Spacing-space-m;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $Spacing-space-m;

      &__title {
        color: $high-emphasis;
        font-size: 30px;
        font-weight: 800;
      }

      &__close {
        width: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 6px;
        padding: 8px;
        border: none;
        border-radius: 50%;
        background: transparent;
        color: $high-emphasis;
        font-size: 14px;
        font-weight: 600;

        &:hover {
          background: rgba(29, 29, 31, 0.12);
        }
      }
    }

    &__image {
      display: block;
      margin-bottom: 12px;

      img {
        width: 100%;
        height: 200px;
        object-fit: contain;
      }
    }

    &__body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      gap: 0;

      &__option {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: $Spacing-space-m;

        width: 35%;
        padding: $Spacing-space-m 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
        justify-content: start;
        align-items: start;
        gap: 8px;

        width: 60%;
        height: 45vh;
        overflow: auto;
        padding-right: 4px;

        &__item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          gap: $Spacing-space-s;

          width: 100%;
          padding: $Spacing-space-s;
          border: 1px solid transparent;
          border-radius: 2px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          img {
            width: 100%;
            object-fit: cover;
          }

          p {
            color: $high-emphasis;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 0;
          }

          &:hover {
            border-color: $low-emphasis;
          }
        }
      }
    }

    .modal-custom__body__content__item--active {
      border: 1px solid $high-emphasis;

      &:hover {
        border-color: $high-emphasis;
      }
    }

    &__footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-top: 16px;
      border-top: 1px solid rgba(29, 29, 31, 0.16);

      &__price {
        color: $high-emphasis;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}
