.orders {
  margin: 0 auto;

  h1 {
    color: var(--Color-Text-high-emphasis, #121212);

    font-family: Manrope;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0.12px;
  }

  p {
    color: var(--Color-Text-medium-emphsis, #525257);

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 40px;
  }

  .order {
    background: #f7f7f7;
    padding: 16px;
    margin-bottom: 16px;
    overflow: hidden;

    .order-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      background: #f5f5f5;
      border-bottom: 1px solid #1d1d1f29;

      .order-id {
        font-size: 14px;
        color: #333;
        font-weight: bold;
      }

      .order-status {
        font-size: 14px;
        font-weight: bold;
        &.confirmed {
          color: var(--Color-Scheme-info, #569dff);

          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.14px;
        }
        &.delivered {
          color: var(--Color-Scheme-success, #49ac3e);

          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.14px;
        }
      }
    }

    .order-details {
      table {
        width: 100%;
        margin-bottom: 16px;
        border-collapse: collapse;

        th {
          text-align: left;
          color: var(--Color-Text-high-emphasis, #121212);

          /* Label/Medium */
          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.14px;
          padding: 10px;
          background: #f9f9f9;
        }

        td {
          padding: 10px;
          color: var(--Color-Text-high-emphasis, #121212);

          font-family: Manrope;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.28px;

          &.product {
            display: flex;
            align-items: center;

            img {
              width: 80px;
              height: 50px;
              margin-right: 10px;
            }

            span {
              font-weight: 500;
            }
          }

          .price {
            display: flex;
            flex-direction: column;

            .original {
              text-decoration: line-through;
              color: #aaa;
              font-size: 12px;
            }

            .discounted {
              color: #ab253f;

              font-family: Manrope;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
              letter-spacing: 0.12px;
            }
          }
        }
      }
    }

    .order-footer {
      background: #f5f5f5;
      border-top: 1px solid #1d1d1f29;

      .group-price {
        margin-top: 16px;

        justify-content: space-between;
        display: flex;
        .total {
          font-size: 14px;
          color: #555;
        }

        .total-price {
          color: var(--Color-Text-high-emphasis, #121212);

          font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.16px;
        }
      }

      .group-btn {
        margin-top: 16px;

        display: flex;
        justify-content: end;

        .btn {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: var(--Radius-radius-xsss, 2px);
          border: 1px solid var(--Outline-Default-outline-click, #121212);
          cursor: pointer;

          &:hover {
            color: #fff;
            border-radius: var(--Radius-radius-xsss, 2px);
background: var(--Color-Base-base-black, #1D1D1F);
          }
        }
      }
    }
  }
}
