.tab {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &.large {
        padding: var(--Spacing-space-s, 8px);
        gap: 6px;

        color: var(--Color-Text-high-emphasis, #121212);

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;
    }
}