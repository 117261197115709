@import "./../../../src/variable.scss";

.cardExpand {
  display: flex;
  flex-direction: column;

  height: 100%;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.08);
  padding: $Spacing-space-m;

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: $Spacing-space-m;

    padding-top: $Spacing-space-m;
    color: $high-white;
    text-align: start;

    &__title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }

    &__content {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;

      ol,
      ul {
        padding-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
}

@media all and (max-width: 576px) {
  .cardExpand {
    display: flex;
    flex-direction: column;

    height: 100%;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.08);
    padding: $Spacing-space-m;

    &__image {
      height: 180px !important;
      width: 100%;
      object-fit: cover;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      gap: $Spacing-space-m;

      padding-top: $Spacing-space-m;
      color: $high-white;
      text-align: start;

      &__title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
      }

      &__content {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
