.card--blog {
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Spacing-space-m, 16px);

    img {
        height: 281px;
        width: 100%;
        object-fit: cover;
        border-radius: 2px;
    }

    &--content {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;

            overflow: hidden;
            color: var(--Color-Text-high-emphasis, #121212);
            text-align: center;
            text-overflow: ellipsis;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.16px;
        }

        .description {
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // gap: 16px;
            // align-self: stretch;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;

            overflow: hidden;
            color: var(--Color-Text-medium-emphsis, #525257);
            text-align: center;
            text-overflow: ellipsis;

            font-family: Manrope;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.24px;
        }
    }
}